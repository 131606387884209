export const columnsDataCheck = [
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Class",
    accessor: "classYear",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];