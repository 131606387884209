import React, { useMemo, useState, useEffect } from "react";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Flex,
    Text,
    Image,
    Button,
    IconButton,
    Icon,
    ButtonGroup,

    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Divider,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { MdComment, MdEdit, MdDelete, MdThumbDown, MdThumbUp } from "react-icons/md";
import Card from "components/card/Card";
import { useDeleteCommentMutation, useDeleteThreadMutation } from "data/slices/threads/threadsSlice";
import { getTimeAgo } from "util/util";
import StripeModalPopup from "components/sidebar/components/StripeModalPopup";

export default function ThreadsTable({ columnsData, tableData, schoolData, isPremiumAccount }) {
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);
    const [cellData, setCellData] = useState(data);

    const tableInstance = useTable(
        {
            columns,
            data: cellData,
            initialState: { pageSize: 15 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex, pageSize },
        gotoPage,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        pageCount,
    } = tableInstance;

    const [selectedDiscussion, setSelectedDiscussion] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDiscussionDeleted, setIsDiscussionDeleted] = useState(false);
    const [isDeleteCommentDialogOpen, setIsDeleteCommentDialogOpen] = useState(false);
    const [isDeleteThreadDialogOpen, setIsDeleteThreadDialogOpen] = useState(false);
    const [commentToDelete, setCommentToDelete] = useState(null);
    const cancelRef = React.useRef();
    const [deleteComment, { isLoading: isDeletingComment }] = useDeleteCommentMutation();
    const [deleteThread, { isLoading: isDeletingThread }] = useDeleteThreadMutation();
    const { isOpen: isOpenPricing, onOpen: onOpenPricing, onClose: onClosePricing } = useDisclosure()

    const handleManageClick = (discussion) => {
        if (!isPremiumAccount) {
            onOpenPricing();
        } else {
            setSelectedDiscussion(discussion);
            setIsModalOpen(true);
        }
    };

    const handleOpenDeleteCommentDialog = (index) => {
        setCommentToDelete(index);
        setIsDeleteCommentDialogOpen(true);
    };

    const handleDeleteComment = async () => {
        try {
            const updatedComments = [...selectedDiscussion.comments];
            const [removedComment] = updatedComments.splice(commentToDelete, 1);

            // Call the mutation to delete the comment
            await deleteComment(removedComment.commentId, selectedDiscussion.threadId);

            setSelectedDiscussion({ ...selectedDiscussion, comments: updatedComments });

            // Update the cell data with the new comment count
            const updatedCellData = cellData.map(discussion =>
                discussion.threadId === selectedDiscussion.threadId
                    ? { ...discussion, comments: updatedComments }
                    : discussion
            );
            setCellData(updatedCellData);

            setIsDeleteCommentDialogOpen(false);
        } catch (error) {
            console.error("Failed to delete comment:", error);
        }
    };

    const handleOpenDeleteThreadDialog = () => {
        setIsDeleteThreadDialogOpen(true);
    };

    const handleDeleteDiscussion = async () => {
        try {
            // Call the mutation to delete the thread
            await deleteThread(selectedDiscussion.threadId);

            // Remove the thread from the data
            const updatedCellData = cellData.filter(discussion => discussion.threadId !== selectedDiscussion.threadId);
            setCellData(updatedCellData);

            setSelectedDiscussion(null);
            setIsModalOpen(false);
            setIsDiscussionDeleted(true);
            setIsDeleteThreadDialogOpen(false);
        } catch (error) {
            console.error("Failed to delete thread:", error);
        }
    };


    if (data && data.length == 0) {
        return (<Flex direction="row" mr="auto" mt={3} align="center">
            <Text color={"gray.600"} fontSize="md" fontWeight="medium" mr="2">
                No Listings Available
            </Text>
        </Flex>)
    }

    return (
        <Card direction="column" w="100%" px="0px" borderRadius="30px" overflowX={{ sm: "scroll", lg: "hidden" }}>
            <StripeModalPopup
                isOpen={isOpenPricing}
                onOpen={onOpenPricing}
                onClose={onClosePricing}
            />
            <Table variant="striped" color="gray.500" mb="24px" {...getTableProps()}>
                {/* <Thead align="center">
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th {...column.getHeaderProps(column.getSortByToggleProps())} pe="10px" borderColor="#959595">
                                    <Flex justify="space-between" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
                                        {column.render("Header")}
                                    </Flex>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead> */}

                <Tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <Td {...cell.getCellProps()} colSpan={columnsData.length} fontSize={{ sm: "14px" }} minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor="transparent">
                                        <Flex justify="space-between" align="center">
                                            <Image src={cell.row.original?.threadImage} w="33px" h="33px" borderRadius="20px" me="16px" />
                                            <Flex direction="column" mr="auto">
                                                <Text color={"black"} fontSize="lg" fontWeight="bold" noOfLines={2}>
                                                    {cell.row.original.threadTitle}
                                                </Text>
                                                {cell.row.original?.threadBody &&
                                                    <Text color={"gray.600"} fontSize="md" mt={3} fontWeight="medium" noOfLines={3}>
                                                        {cell.row.original?.threadBody}
                                                    </Text>}
                                                {cell.row.original.imgs && cell.row.original.imgs[0] &&
                                                    <Flex key={0} mt={3} mb={3}>
                                                        <Image src={cell.row.original?.imgs[0]} w="100px" h="100px" borderRadius="20px" me="16px" />
                                                    </Flex>}
                                                <Flex direction="row" mr="auto" mt={3} align="center">
                                                    <Text color={"gray.600"} fontSize="md" fontWeight="medium" mr="2">
                                                        {cell.row.original.comments?.length}
                                                    </Text>
                                                    <Icon as={MdComment} style={{ fontSize: "1.2em", color: "gray.600", marginRight: "5px" }} />
                                                </Flex>
                                            </Flex>
                                            <Flex direction="column">
                                                <Flex align="center">
                                                    <Icon as={MdThumbUp} color="green.500" mr="1" />
                                                    <Text>{cell.row.original.numUpvotes}</Text>
                                                </Flex>
                                                <Flex align="center" mt={2}>
                                                    <Icon as={MdThumbDown} color="red.500" mr="1" />
                                                    <Text>{cell.row.original.numDownvotes}</Text>
                                                </Flex>
                                            </Flex>
                                            <Flex ml={10} direction="column" align="center">
                                                <Button colorScheme="teal"
                                                    size="sm" onClick={() => handleManageClick(cell.row.original)}>
                                                    manage
                                                </Button>
                                                <Text color={"gray.600"} fontSize="sm" fontWeight="medium" mt="5">
                                                    {getTimeAgo(cell.row.original.createdOn)}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Td>
                                ))}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>

            <Flex justifyContent='center' mt='2'>
                <ButtonGroup size='sm' isAttached variant='outline'>
                    <IconButton
                        icon={<Icon as={ChevronLeftIcon} />}
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                        aria-label='Previous Page'
                    />
                    {Array.from({ length: pageCount }, (_, index) => (
                        <Button
                            key={index}
                            variant='outline'
                            colorScheme={pageIndex === index ? 'teal' : undefined}
                            onClick={() => gotoPage(index)}
                        >
                            {index + 1}
                        </Button>
                    ))}
                    <IconButton
                        icon={<Icon as={ChevronRightIcon} />}
                        onClick={nextPage}
                        disabled={!canNextPage}
                        aria-label='Next Page'
                    />
                </ButtonGroup>
            </Flex>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader align="center">
                        <Flex justifyContent='center' mt='2'>
                            <Image src={selectedDiscussion?.threadImage} w="33px" h="33px" borderRadius="20px" me="16px" />

                            <Text fontSize="lg" fontWeight="bold">
                                {!selectedDiscussion?.isAnonymous ? selectedDiscussion?.firstName : "Anonymous"}

                            </Text>
                        </Flex>
                    </ModalHeader>

                    <ModalCloseButton />
                    <ModalBody>
                        {selectedDiscussion && (
                            <>
                                <Text fontSize="lg" fontWeight="bold" mb={2}>
                                    {selectedDiscussion.threadTitle}
                                </Text>
                                <Text fontSize="md" color="gray.600" mb={4}>
                                    {selectedDiscussion.threadBody}
                                </Text>
                                {selectedDiscussion.imgs && selectedDiscussion.imgs[0] && (
                                    <Flex key={0} justify="center" align="center" mb={3}>
                                        <Image
                                            src={selectedDiscussion.imgs[0]}
                                            w="400px"
                                            h="400px"
                                            borderRadius="20px"
                                            me="16px"
                                        />

                                    </Flex>
                                )}

                                {selectedDiscussion.comments && selectedDiscussion.comments.length > 0 && <Divider mb={4} />}

                                {selectedDiscussion.comments && selectedDiscussion.comments.map((comment, index) => (
                                    <Flex key={index} align="center" mb={3}>
                                        <Image
                                            src={comment.commentOwnerImg ? comment.commentOwnerImg : schoolData.logoUrl}
                                            w="33px"
                                            h="33px"
                                            borderRadius="full"
                                            me="3"
                                        />
                                        <Text flex="1">{comment.commentContent}</Text>
                                        <IconButton
                                            icon={<Icon as={MdDelete} />}
                                            colorScheme="red"
                                            onClick={() => handleOpenDeleteCommentDialog(index)}
                                            aria-label="Remove Comment"
                                            size="sm"
                                        />
                                    </Flex>
                                ))}
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={handleOpenDeleteThreadDialog}>
                            Remove Discussion
                        </Button>
                        <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >

            <AlertDialog
                isOpen={isDeleteCommentDialogOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsDeleteCommentDialogOpen(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Remove Comment
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to remove this comment? This action cannot be undone.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setIsDeleteCommentDialogOpen(false)}>
                                Cancel
                            </Button>
                            <Button isLoading={isDeletingComment} colorScheme="red" onClick={handleDeleteComment} ml={3}>
                                Confirm
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <AlertDialog
                isOpen={isDeleteThreadDialogOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsDeleteThreadDialogOpen(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Remove Discussion
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to delete this discussion? This action cannot be undone.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setIsDeleteThreadDialogOpen(false)}>
                                Cancel
                            </Button>
                            <Button isLoading={isDeletingThread} colorScheme="red" onClick={handleDeleteDiscussion} ml={3}>
                                Confirm
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            {/* {isDiscussionDeleted && (
                <Text color="green.500" textAlign="center" mt={2}>
                    Discussion has been deleted.
                </Text>
            )} */}
        </Card >
    );
}
