/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Spinner,
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  Text,
  useStatStyles
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect } from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/TopListings";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import { Image } from '@chakra-ui/react'

import wallet from "assets/img/roundPics/wallet.png";
import moneySign from "assets/img/roundPics/moneySign.png";
import coinStack from "assets/img/roundPics/coinStack.png";
import checkAdd from "assets/img/roundPics/checkAdd.png";
import students from "assets/img/roundPics/students.png";
import marketplace from "assets/img/roundPics/marketplace.png";
import services from "assets/img/roundPics/services.png";
import threads from "assets/img/roundPics/threads.png";
import connect from "assets/img/roundPics/connect.png";
import events from "assets/img/roundPics/events.png";
import housing from "assets/img/roundPics/housing.png";
import convos from "assets/img/roundPics/convos.png";

import checkList from "assets/img/roundPics/checkList.png";
import bankBuilding from "assets/img/roundPics/bankBuilding.png";
import { useGetUserStatsQuery, useGetUserQuery } from "../../../data/slices/users/usersSlice";
import { useSelector } from "react-redux";


export default function UserReports() {
  // Chakra Color Mode
  const { data: userDetails, isLoading: isLoadingUser, isSuccess: isSuccessUser, isError: isErrorUser, error: userError } = useGetUserQuery();
  const {
    data: stats,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetUserStatsQuery(userDetails?.school)
  //   const user = useSelector((state) =>
  //   getUserStats(state, "99")
  // );
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  // useEffect(() => {
  //   console.log(stats)
  // }, [stats]);




  if (isLoading || isLoadingUser) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        height='100vh'
      >
        <Spinner size='xl' />
      </Box>
    )
  }

  if (isError || isErrorUser) {
    return <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      height='100vh'
    >      <Text
      color={textColor}
      fontSize='34px'
      fontWeight='700'
      lineHeight='100%'>Error: {error.status}</Text></Box>
  }


  return (
    <Box pt={{ xl: "80px" }} bg='#ecf9e5'>
      <Flex align='center' w='100%' px='15px' py='10px'>
        <Text
          me='auto'
          color={textColor}
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'>
          Progress
        </Text>
      </Flex>
      <SimpleGrid
        columns={[2, null, 3]}
        gap='20px'
        mb='20px'
        width='90%'>
        <MiniStatistics
          startContent={
            <Image
              borderRadius='full'
              bg='black'
              w='10px'
              h='10px'
              resize='contain'
              boxSize='56px'
              src={students}
              alt='students'
            />
          }
          name='Total Students'
          value={stats.numStudents}
        />
        <MiniStatistics
          startContent={
            <Image
              borderRadius='full'
              bg='black'
              w='10px'
              h='10px'
              resize='contain'
              boxSize='56px'
              src={moneySign}
              alt='moneySign'
            />
          }
          isPrice={true}

          name='Total Student Listings Value'
          value={stats.totalStudentListingsValue}
        />
        <MiniStatistics
          startContent={
            <Image
              borderRadius='full'
              bg='black'
              w='10px'
              h='10px'
              resize='contain'
              boxSize='56px'
              src={moneySign}
              alt='moneySign'
            />
          }
          isPrice={true}

          name='Total Business Listings Value'
          value={stats.totalBusinessListingsValue}
        />


      </SimpleGrid>

      <Flex align='center' w='100%' px='15px' py='10px'>
        <Text
          me='auto'
          color={textColor}
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'>
          Active Listings
        </Text>
      </Flex>
      <SimpleGrid
        columns={[2, null, 3]}
        gap='20px'
        mb='20px'
        width='90%'>
        <MiniStatistics
          startContent={
            <Image
              borderRadius='full'
              bg='black'
              w='10px'
              h='10px'
              resize='contain'
              boxSize='56px'
              src={marketplace}
              alt='marketplace'
            />
          }
          name='Marketplace'
          value={stats.numMarketplaceListings}
        />
        <MiniStatistics
          startContent={
            <Image
              borderRadius='full'
              bg='black'
              w='10px'
              h='10px'
              resize='contain'
              boxSize='56px'
              src={housing}
              alt='housing'
            />
          }
          name='Housing'
          value={stats.numHousingListings}
        />
        <MiniStatistics
          startContent={
            <Image
              borderRadius='full'
              bg='black'
              w='10px'
              h='10px'
              resize='contain'
              boxSize='56px'
              src={services}
              alt='services'
            />
          }
          name='Services'
          value={stats.numServicesListings}
        />


      </SimpleGrid>
      <Flex align='center' w='100%' px='15px' py='10px'>
        <Text
          me='auto'
          color={textColor}
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'>
          Campus Life
        </Text>
      </Flex>
      <SimpleGrid
        columns={[2, null, 3]}
        gap='20px'
        mb='20px'
        width='90%'>



        <MiniStatistics
          startContent={
            <Image
              borderRadius='full'
              bg='black'
              w='10px'
              h='10px'
              resize='contain'
              boxSize='56px'
              src={threads}
              alt='threads'
            />
          }
          name='Threads'
          value={stats.numThreads}
        />
        <MiniStatistics
          startContent={
            <Image
              borderRadius='full'
              bg='black'
              w='10px'
              h='10px'
              resize='contain'
              boxSize='56px'
              src={convos}
              alt='convos'
            />
          }
          name='Conversations'
          value={stats.numActiveConversations}
        />
        <MiniStatistics
          startContent={
            <Image
              borderRadius='full'
              bg='black'
              w='10px'
              h='10px'
              resize='contain'
              boxSize='56px'
              src={connect}
              alt='connect'
            />
          }
          name='Connect Profiles'
          value={stats.numConnectProfiles}
        />

      </SimpleGrid>

      {/* <SimpleGrid
        columns={{ "2xl": 2 }}
        gap='20px'
        mb='20px'
        width='90%'>
        <WeeklyRevenue
          columnsData={columnsDataComplex}
          tableData={stats.topListingStats}
          trafficData={monthlyTraffic}
        />
        <TotalSpent userDetails={userDetails} userStats={stats} />

      </SimpleGrid> */}
      {/*
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid>
        */}
    </Box>
  );
}
