// Chakra imports
// Custom components
import {
    Stack,
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
    FormErrorMessage,
    Select,
    InputLeftElement,
    Textarea,
    Avatar,
    Alert
} from "@chakra-ui/react";
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import { Formik, Form, Field, validateYupSchema } from "formik";
import Card from "components/card/Card.js";
import React, { forwardRef, useRef, useImperativeHandle, useState, useEffect } from "react";
// import { Select } from "chakra-react-select";
import { PhoneIcon, AddIcon, WarningIcon, CheckIcon, LinkIcon, EmailIcon, EditIcon, Search2Icon } from '@chakra-ui/icons'
// Assets
import { FaBath, FaBed } from "react-icons/fa";
import { DatePicker } from 'chakra-ui-date-input'
import { useGetSchoolsDataQuery, useGetSubcategoriesMarketplaceQuery } from "../../../data/slices/listings/listingsslice";
import { generateDropDownSchools, generateSelectedSchoolDropDowns } from "../../../util/util";
import goldenStar from "assets/img/auth/golden_star.png";
import GooglePlacesInput from "../listing/components/GooglePlaceInput";
import * as Yup from 'yup';

const Upload = forwardRef(function Upload(props, ref) {
    const { data, isPlanEligibleToPostToAllSchools, handleSubmit, subcategories, isEditingDisabled } = props;

    const {
        title,
        _id,
        schools,
        price,
        category,
        ownerEmail,
        status,
        contactEmail,
        contactPhone,
        images,
        soldOn,
        views,
        usefulLink,
        subcategory,
        description,
    } = data;

    useEffect(() => {
        console.log("data passed is ", data)
        console.log("subcategory is ", subcategory)

    }, [data]);

    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const [schoolInputValue, setSchoolInputValue] = useState('');
    const { data: schoolData, isLoading: isLoadingSchoolData } = useGetSchoolsDataQuery(schoolInputValue);
    const [selectedItems, setSelectedItems] = React.useState(schools ? generateSelectedSchoolDropDowns(schools) : []);
    const [schoolErrorMsg, setSchoolErrorMsg] = React.useState(null);
    const formikRef = useRef(null);
    useImperativeHandle(ref, () => {
        return {
            submit() {
                formikRef.current.validateForm().then(errors => {
                    if (Object.keys(errors).length === 0) {
                        formikRef.current.submitForm();
                    } else {
                        console.log("error validating marketplace form", errors)
                    }
                });
            }
        };
    }, []);

    const handleSelectedItemsChange = (newSelectedItems, setFieldValue) => {
        if (newSelectedItems) {
            const hadAllValue = selectedItems ? selectedItems.some(item => item.school === "All") : false;
            const hasAllValue = newSelectedItems.some(item => item.school === "All");
            var newList = hasAllValue && !hadAllValue && isPlanEligibleToPostToAllSchools ? [{ school: "All", label: "All", value: "All", logoUrl: goldenStar }] : newSelectedItems.filter(item => item.school !== "All" || item.label !== "All");
            setSelectedItems(newList);
            setFieldValue('schools', newList ? newList.map(obj => obj.school ? obj.school : obj.label) : null);
        }
    };

    const validationSchema = Yup.object().shape({
        price: Yup.number()
            .typeError('Price must be a number.')
            .required('Price is required.'),
        title: Yup.string().required('Title is required.'),
        // schools: Yup.array().required('School value is required.'),
        email: Yup.string().email('Invalid email.').required('Email is required.'),
        phone: Yup.string().nullable().matches(/^\d+$/, 'Invalid phone number.'),
        subcategory: Yup.string().required('Subcategory is required.'),
        usefulLink: Yup.string()
            .optional()
            .matches(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/,
                'Please enter a valid URL')
    });

    const handleSchoolInputChange = async (event) => {
        if (event.target.value) {
            setSchoolInputValue(event.target.value);
            // setItems(schoolsData);
        } else {
            setSchoolInputValue('');
            // setItems(schoolsData);

        }
    };



    const customRender = (selected) => {
        return (
            <Flex flexDir="row" alignItems="center">

                {selected.logoUrl ? <Avatar mr={2} size="sm" src={selected.logoUrl} /> : <Avatar mr={2} size="sm" name={selected.school} />}
                <Text>{selected.school}</Text>
            </Flex>
        )
    }

    return (
        <Card
            borderTopLeftRadius='20px'
            borderBottomLeftRadius='20px'
            direction='column'
            px='15px'
            overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Formik
                innerRef={formikRef}
                validationSchema={validationSchema}
                initialValues={{
                    listingId: _id,
                    price: price,
                    title: title,
                    type: "Marketplace",
                    // schools: schools,
                    email: contactEmail,
                    phone: contactPhone,
                    description: description,
                    subcategory: subcategory,
                    usefulLink: usefulLink

                }}
                onSubmit={async (values, { setSubmitting, setErrors, setStatus, resetForm, setValues }) => {
                    /**
                     * this is a temporary fix
                     * for some reason props.setFieldValue does not set values if thhe array is one element?
                     * 
                     */
                    // if (selectedItems && (!values.schools || values.schools.length == 0)) {
                    //     values.schools = selectedItems.map(obj => obj.school ? obj.school : obj.label);
                    // }
                    // const doesNotHaveSchoolValue = !values.schools || values.schools.length == 0;
                    try {
                        // if (doesNotHaveSchoolValue) {
                        //     setSchoolErrorMsg('You must select at least one school');
                        //     return;
                        // } else {
                        //     await handleSubmit(values, setSelectedItems);
                        //     setSchoolErrorMsg(null);
                        // }
                        await handleSubmit(values, setSelectedItems);
                        setSchoolErrorMsg(null);
                    } catch (error) {
                        setStatus({ success: false })
                        setSubmitting(false)
                        setErrors({ submit: error.message })
                    }
                }}
            >
                {(props) => (
                    <Form style={{ overflow: 'hidden' }}>

                        <Stack direction='row' mt='10px'>
                            <Field name="price" >
                                {/* Trouble in this line below*/}
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.price && form.touched.price}>
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'>
                                            Price<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size='md'>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                color='gray.300'
                                                fontSize='1.2em'
                                                children='$'
                                                mt='3px'
                                            />
                                            <Input
                                                {...field} id="price"
                                                isRequired={true}
                                                fontSize='sm'
                                                placeholder='850'
                                                mb='2px'
                                                size='lg'
                                                isDisabled={false}
                                                type={"number"}
                                                variant='auth'
                                                w='90%'
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.price}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="title" >
                                {/* Trouble in this line below*/}
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.title && form.touched.title}>

                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'>
                                            Listing Title<Text color={brandStars}>*</Text>
                                        </FormLabel>

                                        <Input
                                            {...field}
                                            isRequired={true}
                                            variant="auth"
                                            fontSize="sm"
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder="Enter title for your post"
                                            mb="12px" // | space
                                            width="95%"
                                            fontWeight="500"
                                            isDisabled={isEditingDisabled}
                                            size="lg"
                                            type="text"
                                        />

                                        <FormErrorMessage>
                                            {form.errors.title}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Stack>

                        <Stack direction='row'>
                            <Field name="email" >
                                {/* Trouble in this line below*/}
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.email && form.touched.email}>

                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'>
                                            Email<Text color={brandStars}></Text>
                                        </FormLabel>
                                        <InputGroup size='md'>

                                            <InputLeftElement
                                                pointerEvents='none'
                                                children={<EmailIcon color='gray.300' mt='5px' />}
                                            />
                                            <Input
                                                {...field} id="email"
                                                isRequired={true}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                type='email'
                                                isDisabled={isEditingDisabled}
                                                placeholder='mail@collegesouk.com'
                                                mb='24px'
                                                fontWeight='500'
                                                size='lg'
                                                w='90%'
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.email}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="phone" >
                                {/* Trouble in this line below*/}
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.phone && form.touched.phone}>

                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={false}
                                            display='flex'>
                                            Phone Number<Text color={brandStars}></Text>
                                        </FormLabel>
                                        <InputGroup size='md'>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                children={<PhoneIcon color='gray.300' mt='5px' />}
                                            />
                                            <Input
                                                {...field} id="phone"
                                                isRequired={false}
                                                fontSize='sm'
                                                placeholder='555-556-5488'
                                                mb='24px'
                                                isDisabled={isEditingDisabled}
                                                size='lg'
                                                type='tel'
                                                variant='auth'
                                                w='90%'
                                                maxLength={10}

                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.phone}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Stack>
                        {/* {schoolErrorMsg ?
                            <Flex flexDirection={"column"} align='center' mb='10px'>
                                <Alert status='error'>
                                    {schoolErrorMsg}
                                </Alert>
                            </Flex> : null} */}
                        {/* <Stack direction='row'>
                            <Field name="schools" >
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.schools && form.touched.schools}>

                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'
                                            width='10rem'
                                        >
                                            School(s)<Text color={brandStars}>*</Text>
                                        </FormLabel>

                                        <CUIAutoComplete
                                            // disableCreateItem={true}

                                            inputStyleProps={{
                                                variant: 'auth',
                                                size: 'lg',
                                                w: '95%',
                                                fontSize: 'sm',
                                                fontWeight: '500',
                                            }}
                                            disableCreateItem={true}
                                            renderCustomInput={(inputProps) => (
                                                <InputGroup size="md">
                                                    <InputLeftElement
                                                        pointerEvents='none'
                                                        children={<Search2Icon color='gray.300' mt='5px' />}
                                                    />
                                                    <Input
                                                        {...field}
                                                        {...inputProps}
                                                        id="schools"
                                                        isRequired={selectedItems && selectedItems.length > 0 ? false : true}
                                                        onInput={handleSchoolInputChange}
                                                        placeholder='Search School(s)'
                                                    />
                                                </InputGroup>
                                            )}
                                            placeholder="Search for School"
                                            hideToggleButton={false}
                                            size="sm"
                                            items={generateDropDownSchools(isLoadingSchoolData, isPlanEligibleToPostToAllSchools, schoolData)}
                                            itemRenderer={customRender}
                                            selectedItems={selectedItems}
                                            onSelectedItemsChange={(changes) => {
                                                handleSelectedItemsChange(changes.selectedItems, props.setFieldValue)
                                            }
                                            }
                                        />
                                        <FormErrorMessage>
                                            {form.errors.schools}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Stack> */}
                        <Stack direction="row" mt="10px">
                            <Field name="subcategory">
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.subcategory && form.touched.subcategory}>
                                        <Select
                                            {...field}
                                            id="subcategory"
                                            defaultValue={subcategory}
                                            placeholder="Subcategory"
                                            variant="auth"
                                            fontSize="sm"
                                            isRequired={true}
                                            isDisabled={isEditingDisabled}
                                            ms={{ base: "0px", md: "0px" }}
                                            mb="12px" // | space
                                            width="95%"
                                            fontWeight="500"
                                            size="lg"
                                        >
                                            {subcategories.map((subcategory) => (
                                                <option key={subcategory} value={subcategory}>
                                                    {subcategory}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>
                                            {form.errors.subcategory}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Stack>
                        <Stack spacing={5} direction='row'>
                            <Field name="usefulLink" >

                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.usefulLink && form.touched.usefulLink}>

                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={false}
                                            display='flex'>
                                            Action Button Link<Text color={brandStars}></Text>
                                        </FormLabel>
                                        <InputGroup size='md'>

                                            <InputLeftElement
                                                pointerEvents='none'
                                                children={<LinkIcon color='gray.300' />}
                                            />
                                            <Input
                                                w='95%'
                                                {...field} id="usefulLink"
                                                isRequired={false}
                                                variant='auth'
                                                isDisabled={isEditingDisabled}
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                placeholder='Enter Link'
                                                mb='12px' // | space
                                                fontWeight='500'
                                                size='lg'
                                                type="link"
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.usefulLink}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Stack>
                        <Field name="description">
                            {({ field, form }) => (
                                <FormControl isInvalid={form.errors.description && form.touched.description}>
                                    <FormLabel
                                        ms="4px"
                                        fontSize="sm"
                                        fontWeight="100"
                                        color={textColorPrimary}
                                        isRequired={true}
                                        display="flex"
                                    >
                                        Additional details<Text color={brandStars}></Text>
                                    </FormLabel>
                                    <InputGroup size="md">
                                        <Input
                                            {...field}
                                            h="150px"
                                            noOfLines={3}
                                            id="description"
                                            isRequired={true}
                                            variant="auth"
                                            isDisabled={isEditingDisabled}
                                            fontSize="sm"
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder="Enter any additional information"
                                            mb="12px" // | space
                                            width="95%"
                                            fontWeight="500"
                                            size="lg"
                                            type="text"
                                            resize="vertical"
                                        />
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {form.errors.description}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                    </Form>
                )}
            </Formik>
        </Card>
    );
})

export default Upload;
