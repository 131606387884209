import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";

const threadsAdapter = createEntityAdapter();

const initialState = threadsAdapter.getInitialState();

export const threadsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getThreadsBySchool: builder.query({
      query: (school) => `/api/threads?school=${school}`,
      providesTags: ['threads']
    }),
    deleteThread: builder.mutation({
      query: (threadId) => ({
        url: `/api/thread`,
        method: 'DELETE',
        params: { threadId },
      }),
      invalidatesTags: ['threads'],
    }),
    getThreadById: builder.query({
      query: ({ threadId, email }) => `/api/thread?threadId=${threadId}&email=${email}`,
      providesTags: ['threads'],
    }),
    reportThread: builder.mutation({
      query: ({ threadId, email }) => ({
        url: `/api/report`,
        method: 'POST',
        params: { threadId, email },
      }),
      invalidatesTags: ['threads'],
    }),
    deleteComment: builder.mutation({
      query: ({ threadId, commentId }) => ({
        url: `/api/delete/comment`,
        method: 'DELETE',
        params: { threadId, commentId },
      }),
      invalidatesTags: ['threads'],
    }),
  }),
});

export const {
  useGetThreadsBySchoolQuery,
  useDeleteThreadMutation,
  useGetThreadByIdQuery,
  useReportThreadMutation,
  useDeleteCommentMutation,
} = threadsApiSlice;

export const selectThreadsBySchool = createSelector(
  (state, school) =>
    threadsApiSlice.endpoints.getThreadsBySchool.select(school),
  (data) => data?.data
);

// Selector to get the thread by ID data from the store
export const selectThreadById = createSelector(
  (state, { threadId, email }) =>
    threadsApiSlice.endpoints.getThreadById.select({ threadId, email }),
  (data) => data?.data
);
