import { Avatar, Box, Flex, Text, useColorModeValue, Image } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import { MdOutlineLogout } from "react-icons/md";

export default function Banner(props) {
  const { avatar, name, job } = props;

  // Chakra Color Mode
  const textColorSecondary = "gray.400";

  return (
    <Card mb={{ base: "0px", lg: "18px" }} width="95%" align="center" height="100%" borderRadius="30px">
      <Flex direction="column" align="center" justify="center" height="100%">
        {/* <Flex w="90%" justify="space-between" mb="10px" h="0px">
          <Flex align="center"></Flex>
          <Text color={"gray"} fontSize="l" mt="5px" align="left">
            Edit
          </Text>
        </Flex> */}

        <Flex align="center" justify="center" flexDirection="column">
          <Image src={avatar} w="75px" h="75px" borderRadius="50%" />
          <Text color="black" fontWeight="bold" fontSize="30" mt="20px">
            {name}
          </Text>
          <Text color={textColorSecondary} fontSize="20" mt="-6px">
            {job}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
