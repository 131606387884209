/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Menu,
    MenuList,
    MenuButton,
    MenuItem,
    Spinner,
    FormErrorMessage,
    InputGroup,
    Textarea,
    Select
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";

import { Box, Flex, Button, Text, SimpleGrid, useDisclosure } from "@chakra-ui/react";
import { useCreateTaskMutation, useGetUserQuery, useGetTasksQuery } from "data/slices/users/usersSlice";


// Custom components



import React, { useState, useEffect } from 'react';


import AllowedUsersTable from "views/admin/dataTables/components/AllowedUsersTable";
import {
    columnsDataCheck,
} from "views/admin/profile/variables/columnsData.js";
import { useGetSchoolAdministratorsQuery } from "data/slices/school/schoolSlice";
import { buildAllowedUsersTable } from "util/util";
import { useAddSchoolAdminitratorMutation } from "data/slices/school/schoolSlice";
export default function PasswordCard() {
    const [addAdmin, { isLoading: isCreatingTask, error: error, data: data }] = useAddSchoolAdminitratorMutation();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
    const [selectedReminder, setSelectedReminder] = useState("Select");
    const handleReminderSelect = (reminder) => { setSelectedReminder(reminder); };
    const [selectedPriority, setSelectedPriority] = useState('');
    const { data: userData, isLoading: isLoadingUser, isSuccess: isSuccessUser, isError: isErrorUser, error: userError } = useGetUserQuery();
    const { data: tasks, isLoading: isLoadingTasks, isSuccess: isSuccessTasks, isError: isErrorTasks, error: tasksError, refresh } = useGetSchoolAdministratorsQuery(userData?.school);

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        // console.log('password card have changed:', buildAllowedUsersTable(tasks));
    }, [tasks]);

    if (isLoadingUser || isLoadingTasks) {
        return (
            <Box display='flex' alignItems='center' justifyContent='center' height='100vh'>
                <Spinner size='xl' />
            </Box>
        );
    }

    let userTasks = buildAllowedUsersTable(tasks);

    return (
        <Box>
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Modal isOpen={isOpen} onClose={handleClose}>
                    <ModalOverlay />
                    <Formik
                        initialValues={{
                            school: userData.school,
                            name: '',
                            email: '',
                            office: '',
                            role: '',
                        }}
                        onSubmit={async (values, { resetForm }) => {
                            try {
                                await addAdmin(values);
                                refresh(); // Refresh the data
                                resetForm(); // Reset form after submission
                            } catch (error) {
                                console.log(error);
                            } finally {
                                handleClose();
                            }
                        }}
                    >
                        {(formikProps) => (
                            <Form>
                                <ModalContent borderRadius="20px" width={{ base: "90%", sm: "80%", md: "60%" }}>
                                    <ModalBody>
                                        <Flex direction="column">
                                            <Box mb="20px">
                                                <ModalHeader>Name</ModalHeader>
                                                <Field name="name">
                                                    {({ field, form }) => (
                                                        <InputGroup size='md'>
                                                            <Input
                                                                {...field}
                                                                isRequired={true}
                                                                placeholder="Enter name"
                                                                borderRadius="20px"
                                                                size="md"
                                                            />
                                                            <FormErrorMessage>
                                                                {form.errors.name}
                                                            </FormErrorMessage>
                                                        </InputGroup>
                                                    )}
                                                </Field>
                                            </Box>
                                            <Box mb="20px">
                                                <ModalHeader>Email</ModalHeader>
                                                <Field name="email">
                                                    {({ field, form }) => (
                                                        <InputGroup size='md'>
                                                            <Input
                                                                {...field}
                                                                isRequired={true}
                                                                placeholder="Enter email"
                                                                borderRadius="20px"
                                                                size="md"
                                                                type="email"
                                                            />
                                                            <FormErrorMessage>
                                                                {form.errors.email}
                                                            </FormErrorMessage>
                                                        </InputGroup>
                                                    )}
                                                </Field>
                                            </Box>
                                            <Box mb="20px">
                                                <ModalHeader>Office</ModalHeader>
                                                <Field name="office">
                                                    {({ field, form }) => (
                                                        <InputGroup size='md'>
                                                            <Input
                                                                {...field}
                                                                isRequired={true}
                                                                placeholder="Enter office"
                                                                borderRadius="20px"
                                                                size="md"
                                                            />
                                                            <FormErrorMessage>
                                                                {form.errors.office}
                                                            </FormErrorMessage>
                                                        </InputGroup>
                                                    )}
                                                </Field>
                                            </Box>
                                            <Box mb="20px">
                                                <ModalHeader>Role</ModalHeader>
                                                <Field name="role">
                                                    {({ field, form }) => (
                                                        <InputGroup size='md'>
                                                            <Select
                                                                {...field}
                                                                isRequired={true}
                                                                placeholder="Select role"
                                                                borderRadius="20px"
                                                                size="md"
                                                            >
                                                                <option value="Super Administrator">Super Administrator</option>
                                                                <option value="Administrator">Administrator</option>
                                                                <option value="Student Worker">Student Worker</option>
                                                            </Select>
                                                            <FormErrorMessage>
                                                                {form.errors.role}
                                                            </FormErrorMessage>
                                                        </InputGroup>
                                                    )}
                                                </Field>
                                            </Box>
                                        </Flex>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button borderRadius="full" ref={cancelRef} onClick={handleClose} size="lg" mx="auto" display="block" bg="#A3F579">
                                            Cancel
                                        </Button>
                                        <Button type="submit" isLoading={isCreatingTask} borderRadius="full" size="lg" mx="auto" display="block" ml={3} bg="#A3F579">
                                            Invite
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Form>
                        )}
                    </Formik>
                </Modal>


            </Flex>
            <SimpleGrid>
                <Flex direction="column" align="flex-end" mb={4}>

                    {userData?.role && (userData?.role !== "Student Worker") &&
                        <Button
                            id="createTaskButton"
                            borderRadius="50px"
                            variant="brand"
                            color="black"
                            background="#A3F579"
                            ms="80px"
                            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)"
                            fontSize="16px" // Smaller font size
                            fontWeight="700"
                            onClick={onOpen}
                            _hover={{ background: "#95dd6e" }}
                            _active={{ color: "#95dd6e" }}
                            outline="none"
                            px="24px" // Padding for better fit
                            py="12px" // Padding for better fit
                        >
                            Add Administrator
                        </Button>}
                </Flex>
                <AllowedUsersTable columnsData={columnsDataCheck} role={userData?.role} email={userData?.email} school={userData?.school} tableData={userTasks} />
            </SimpleGrid>
        </Box>
    );
}


