import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";

const schoolsAdapter = createEntityAdapter();

const initialState = schoolsAdapter.getInitialState();

export const schoolsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSchoolDetails: builder.query({
      query: (schoolName) => `/api/v2/school?schoolName=${schoolName}`,
      providesTags: ['school']
    }),
    getSchoolGreekOrgs: builder.query({
      query: (school) => `/api/v2/school/greek?school=${school}`,
      providesTags: ['school'],
    }),
    updateSchoolLocation: builder.mutation({
      query: ({ schoolName, longitude, latitude }) => ({
        url: `/api/v2/school/location`,
        method: 'PUT',
        params: { schoolName, longitude, latitude },
      }),
      invalidatesTags: ['school'],
    }),
    addExternalUser: builder.mutation({
      query: ({ email, school }) => ({
        url: `/api/v2/school/external/user`,
        method: 'POST',
        params: { email, school },
      }),
      invalidatesTags: ['school'],
    }),
    removeExternalUser: builder.mutation({
      query: (email) => ({
        url: `/api/v2/school/external/user`,
        method: 'DELETE',
        params: { email },
      }),
      invalidatesTags: ['school'],
    }),
    addSchoolAdminitrator: builder.mutation({
      query: (formData) => ({
        url: '/api/v2/school/invite/administrator',
        method: 'POST',
        body: formData
      }),
      invalidatesTags: ['admins'],

    }),
    removeSchoolAdminitrator: builder.mutation({
      query: ({ email, school }) => ({
        url: `/api/v2/school/administrator?email=${email}&school=${school}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['admins'],
    }),
    getSchoolAdministrators: builder.query({
      query: (schoolName) => `/api/v2/school/administrators?school=${schoolName}`,
      providesTags: ['admins']
    }),
  }),
});

export const {
  useGetSchoolDetailsQuery,
  useGetSchoolGreekOrgsQuery,
  useUpdateSchoolLocationMutation,
  useAddExternalUserMutation,
  useRemoveExternalUserMutation,
  useAddSchoolAdminitratorMutation,
  useGetSchoolAdministratorsQuery,
  useRemoveSchoolAdminitratorMutation
} = schoolsApiSlice;

// Selector to get the school details data from the store
export const selectSchoolDetails = createSelector(
  (state) => {
    const getSchoolDetailsQuery = schoolsApiSlice.endpoints.getSchoolDetails.select(state);
    return getSchoolDetailsQuery ? getSchoolDetailsQuery.data : undefined;
  },
  (data) => {
    console.log('Selected school details:', data);
    return data || {};
  }
);

// Selector to get the school Greek organizations data from the store
export const selectSchoolGreekOrgs = createSelector(
  (state, school) =>
    state.schoolsApiSlice.endpoints.getSchoolGreekOrgs.select(school),
  (data) => data?.data
);
