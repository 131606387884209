import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";

const connectProfilesAdapter = createEntityAdapter();

const initialState = connectProfilesAdapter.getInitialState();

export const connectApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    hideProfileAndIssueWarning: builder.mutation({
      query: (email) => ({
        url: `/api/connect/hide`,
        method: 'GET',
        params: { email },
      }),
      invalidatesTags: ['connectProfiles'],
    }),
    requestUserChanges: builder.mutation({
      query: ({ email, note }) => ({
        url: `/api/connect/changes`,
        method: 'GET',
        params: { email, note },
      }),
      invalidatesTags: ['connectProfiles'],
    }),
    getConnectProfiles: builder.query({
      query: (school) => `/api/connect?school=${school}`,
      providesTags: ['connectProfiles']
    }),
  }),
});

export const {
  useHideProfileAndIssueWarningMutation,
  useRequestUserChangesMutation,
  useGetConnectProfilesQuery,
} = connectApiSlice;

export const selectConnectProfilesBySchool = createSelector(
  (state, school) =>
    connectApiSlice.endpoints.getConnectProfiles.select(school),
  (data) => data?.data
);
