import React, { useMemo, useState, useEffect } from "react";
import {
    Divider,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    ButtonGroup,
    Icon,
    Button,
    IconButton,
    Alert,
    AlertIcon,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon, DeleteIcon } from '@chakra-ui/icons';
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { MdAccessTime } from 'react-icons/md'; // Importing a clock icon from react-icons

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { getTimeAgo, timestampToDateTime, buildAnnouncementsTable } from "util/util";
import { buildAllowedUsersTable } from "util/util";
import { useRemoveSchoolAdminitratorMutation } from "data/slices/school/schoolSlice";

export default function AllowedUsersTable(props) {
    const { columnsData, tableData, email, school, role } = props;
    const [removeAdmin, { isLoading: isDeleting, error: error }] = useRemoveSchoolAdminitratorMutation();

    const [data, setData] = useState(buildAllowedUsersTable(tableData));
    const [isOpen, setIsOpen] = useState(false);

    const [selectedUserId, setSelectedUserId] = useState(null);
    const cancelRef = React.useRef();

    useEffect(() => {
        setData(buildAllowedUsersTable(tableData));
    }, [tableData]);

    const handleDelete = (userId) => {
        setIsOpen(true);
        setSelectedUserId(userId);
    };



    const confirmDelete = async () => {

        try {
            await removeAdmin({ email: selectedUserId, school: school });
            const newData = data.filter(user => user.email !== selectedUserId);
            setData(newData);
            setIsOpen(false);
            setSelectedUserId(null);
        } catch (error) {
            console.error("Failed to delete the user:", error);
        }

    };

    const columns = useMemo(() => [
        ...columnsData,
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => handleDelete(row.original.email)}
                    aria-label='Delete User'
                />
            ),
        }
    ], [columnsData, data]);

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 15 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex, pageSize = 15 },
        gotoPage,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        pageCount,
    } = tableInstance;

    const textColor = useColorModeValue("secondaryGray.900", "white");

    const getAlertComponent = (status, value) => {
        switch (status) {
            case false:
                return (
                    <Alert status='warning'>
                        <AlertIcon as={MdAccessTime} />
                        {"Invited"}
                    </Alert>
                );
            case true:
                return (
                    <Alert status='success'>
                        <AlertIcon />
                        {"Active"}
                    </Alert>
                );
            default:
                return (
                    <Alert status='info'>
                        <AlertIcon />
                        {value}
                    </Alert>
                );
        }
    };

    return (
        <Card
            direction='column'
            w='100%'
            px='0px'
            borderRadius='30px'
            overflowX={{ sm: "scroll", lg: "hidden" }}
        >
            <Table {...getTableProps()} variant='striped' color='gray.500' mb='24px'>
                <Thead align='center'>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                                <Th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    pe='10px'
                                    key={index}
                                    borderColor='#959595'
                                >
                                    <Flex
                                        justify='space-between'
                                        align='center'
                                        fontSize={{ sm: "10px", lg: "12px" }}
                                        color='gray.400'
                                    >
                                        {column.render("Header")}
                                    </Flex>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()} key={index}>
                                {row.cells.map((cell, index) => {
                                    let cellContent = "";
                                    switch (cell.column.Header) {
                                        case "Email":
                                            cellContent = (
                                                <Flex align='center'>
                                                    <Text color={"black"} fontSize='sm' fontWeight='700'>
                                                        {cell.value}
                                                    </Text>
                                                </Flex>
                                            );
                                            break;
                                        case "Name":
                                            cellContent = (
                                                <Flex align='center'>
                                                    <Text maxWidth={300} color={"black"} noOfLines={2} fontSize='sm' fontWeight='700'>
                                                        {cell.value}
                                                    </Text>
                                                </Flex>
                                            );
                                            break;
                                        case "Office":
                                            cellContent = (
                                                <Text color="black" fontSize='sm' fontWeight='700'>
                                                    {cell.value}
                                                </Text>
                                            );
                                            break;
                                        case "Invite Status":
                                            cellContent = getAlertComponent(cell.value, cell.value);
                                            break;
                                        case "Role":
                                            cellContent = (
                                                <Text color="black" fontSize='sm' fontWeight='700'>
                                                    {cell.value}
                                                </Text>
                                            );
                                            break;
                                        case "Actions":
                                            cellContent = (
                                                (email != row.original.email ? role == "Super Administrator" ?
                                                    <IconButton
                                                        icon={<DeleteIcon color={"red"} />}
                                                        onClick={() => handleDelete(row.original.email)}
                                                        aria-label='Delete User'
                                                        disabled={role != "Super Administrator"}
                                                    /> :
                                                    <Text color="black" fontSize='sm' fontWeight='700'>
                                                        Permissions Required
                                                    </Text> : null)
                                            );
                                            break;
                                        default:
                                            break;
                                    }
                                    return (
                                        <Td
                                            {...cell.getCellProps()}
                                            key={index}
                                            fontSize={{ sm: "14px" }}
                                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                            borderColor='transparent'
                                        >
                                            {cellContent}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            {/* Pagination */}
            <Flex justifyContent='center' mt='2'>
                <ButtonGroup size='sm' isAttached variant='outline'>
                    <IconButton
                        icon={<Icon as={ChevronLeftIcon} />}
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                        aria-label='Previous Page'
                    />
                    {Array.from({ length: pageCount }, (_, index) => (
                        <Button
                            key={index}
                            variant='outline'
                            colorScheme={pageIndex === index ? 'blue' : undefined}
                            onClick={() => gotoPage(index)}
                        >
                            {index + 1}
                        </Button>
                    ))}
                    <IconButton
                        icon={<Icon as={ChevronRightIcon} />}
                        onClick={nextPage}
                        disabled={!canNextPage}
                        aria-label='Next Page'
                    />
                </ButtonGroup>
            </Flex>

            {/* Delete Confirmation Dialog */}
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsOpen(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Remove User
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to delete this user? This action cannot be undone.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setIsOpen(false)}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" isLoading={isDeleting} onClick={confirmDelete} ml={3}>
                                Confirm
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Card>
    );
}
