// Chakra imports
import {
  Badge,
  Box,
  Flex,
  Icon,
  Progress,
  Text,
  useColorModeValue,
  Switch,
  FormLabel,
  FormControl,
  SimpleGrid,
  Stack,
  useDisclosure,
  Spinner,
  Button,
} from "@chakra-ui/react";
// Custom components
import { FaCreditCard } from "react-icons/fa";
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import Menu from "components/menu/MainMenu";
import React from "react";
import { MdAssistantDirection } from "react-icons/md";
import StripeModalPopup from "components/sidebar/components/StripeModalPopup";
import { useGetSubManagementPortalByIdMutation } from "../../../../data/slices/subscription/subscription";
import { apiSlice } from "../../../../data/api/apiSlice";
import { isFreeTrial, isPremium } from "../../../../util/util";



// Assets
import { MdCheckCircle, MdCancel, MdOutlineError, MdVisibility, MdOutlineTimer } from "react-icons/md";

export default function Banner(props) {
  const { subDetails, userEmail } = props;
  const [goToPortalManagement, { isLoading: isLoadingPortal, isError }] = useGetSubManagementPortalByIdMutation()

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    plan,
    status,
    startDate,
    endDate,
    billingZipcode,
    billingAddress,
    billingCity,
    billingCountry,
    ccDigits,
    ccType,
    ccExpirationDate
  } = subDetails;


  const handleManagementPortalClick = async () => {
    try {
      const subscriptionUrl = await goToPortalManagement(userEmail).unwrap();
      if (subscriptionUrl) {
        window.location = subscriptionUrl.stripeCustomerUrl;
      }
    } catch (err) {
      console.log(err)
    }
  }
  if (!isPremium(subDetails) || isFreeTrial(subDetails)) {
    return (

      <Card
        mb={{ base: "0px", lg: "18px" }}
        width='95%'
        align='center'
        height='75%'
        borderRadius='30px'
        overflow='hidden'
      >
        <Text color='black' fontSize='30' mt='10px' mb='10px' variant='brand'>
          Subscription
        </Text>
        <Flex w='90%' justify='space-between' mb='10px'>
          <Flex align='center'>
            <Button color={'black'} fontSize='18' fontWeight='semibold'
              mt='5px' align='left'
              _hover={{
                textDecoration: 'underline',
                color: '#BCE5AD',
              }}
              onClick={onOpen}
              rightIcon={<Icon as={MdAssistantDirection} style={{ fontSize: "1.2em" }} />}
              // rightIcon={() => (
              //   <Icon as={MdAssistantDirection}
              //     _hover={{
              //       textDecoration: 'underline',
              //       color: '#BCE5AD',
              //     }}
              //     mt='30%' align='left' style={{ fontSize: '1.2em' }} onClick={onOpen} />
              // )}
              ms='15px'>
              Subscribe to Souk Premium
            </Button>
          </Flex>

        </Flex>
        <Flex w='90%' justify='space-between' ml='15px'>
          <Flex align='center'>
            <Text
              color={'gray'}
              fontSize='l'
              mt='5px'
              align='center'
              ms='15px'
              cursor='pointer'
              transition='all 0.2s ease-in-out'
              _hover={{
                textDecoration: 'underline',
                color: '#BCE5AD',
              }}
              onClick={onOpen}
            >
              View Souk Plans
            </Text>
          </Flex>
        </Flex>

        <StripeModalPopup
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      </Card>
    );
  }


  return (
    <Card mb={{ base: "0px", lg: "18px" }} width='95%' align='center' height='90%' borderRadius='30px' >
      <StripeModalPopup
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
      <Text color='black' fontSize='30' mt='10px' variant='brand'>
        Subscription
      </Text>
      <Box w='100%' mt='10px' ms='15px'>
        {/* <Flex w='100%' justify='space-between' mb='10px' position='relative'>
          <Flex align='center'>
            <Text color={'black'} fontSize='25' fontWeight='semibold' mt='5px' align='left'>
              Souk Premium
            </Text>
          </Flex>
        </Flex> */}
      </Box>
      <Badge ml='1' mb='10px' variant='solid' colorScheme={status === 'ACTIVE' ? 'green' : 'red'} style={{ fontSize: '14px', right: '0' }}>
        {status === 'ACTIVE' ? 'Active' : 'Cancelled'}
      </Badge>
      <Text color={'black'} fontSize='l' mt='1px' align='left' ms='15px'>
        Current period:
      </Text>
      <Text color={'black'} fontSize='l' mt='1px' align='left' ms='15px'>
        {startDate} - {endDate}
      </Text>
      <Flex w='90%' justify='space-between' mt='10px'>
        <Flex alignItems="center" padding="4">
          <Icon as={FaCreditCard} boxSize="30px" />
          <Flex flexDirection="column" marginLeft="4" justify='space-between'>
            <Flex flexDirection="row" marginLeft="4" justify='space-between'>
              <Text fontWeight="bold" color={'black'} fontSize='l' >{ccType ? ccType.toUpperCase() : null}</Text>
              <Text fontWeight="bold" color={'black'} fontSize='l' ml='10px'>**** {ccDigits}</Text>
            </Flex>
            <Flex flexDirection="row" marginLeft="4" justify='space-between'>
              <Text fontWeight="bold" color={'black'} fontSize='l' >Expires {ccExpirationDate}</Text>
            </Flex>

          </Flex>
        </Flex>
      </Flex>
      <Flex w='90%' justify='space-between' mb='10px'>
        <Button
          color="black"
          fontSize="18"
          fontWeight="semibold"
          mt="5px"
          textAlign="left"
          rightIcon={<Icon as={MdAssistantDirection} style={{ fontSize: "1.2em" }} />}
          _hover={{
            textDecoration: 'underline',
            color: '#BCE5AD',
          }}
          onClick={() => handleManagementPortalClick()}
        >
          Manage your subscription
        </Button>
        {/* <Flex align='right'>
          <Icon as={MdAssistantDirection} mt='30%' align='left' style={{ fontSize: '1.2em' }} _hover={{
            textDecoration: 'underline',
            color: '#BCE5AD',
          }} onClick={() => handleManagementPortalClick()} />
        </Flex> */}

      </Flex>
      {isError ?
        <Flex>
          <Text color={'red'} fontSize='l' mb='10px' align='left' ms='12px'>
            Oops, something went wrong. Please try again!
          </Text>
        </Flex> : null}

    </Card>
  );
}
