export const columnsDataCheck = [
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Office",
    accessor: "office",
  },
  {
    Header: "Role",
    accessor: "role",
  },

  {
    Header: "Invite Status",
    accessor: "acceptedInvite",
  }
];