import moment from 'moment';
import Cookies from 'js-cookie';
import goldenStar from "assets/img/auth/golden_star.png";
import { formatDistanceToNow } from 'date-fns';
import numeral from 'numeral';

export function formatPrice(price) {
    if (price == null) return null;
    const [whole, decimal] = price.toString().split('.');
    const wholeWithCommas = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (decimal == null) return `$${wholeWithCommas}`;
    return `$${wholeWithCommas}.${decimal}`;
}

export function formatBigNumbers(number) {
    if (number === null || number === undefined) {
        return '';
    }

    if (number < 1000) {
        return number.toString();
    } else if (number >= 1000 && number < 1000000) {
        const roundedNumber = numeral(number).format('0.0a');
        return roundedNumber.toLowerCase();
    } else {
        const roundedNumber = numeral(number).format('0.0a');
        return roundedNumber.toUpperCase();
    }
}


export function formatPercentage(number) {
    if (number === null || isNaN(number)) {
        return null;
    }

    const sign = number < 0 ? '-' : '+';
    const formattedNumber = formatBigNumbers(Math.abs(number).toFixed(0));

    return `${sign}${formattedNumber}%`;
}

export function timestampToDateTime(timestamp) {
    // Convert seconds to milliseconds
    if (!timestamp) return "";
    var milliseconds = timestamp * 1000;

    // Create a new Date object with the timestamp
    var date = new Date(milliseconds);

    // Extract the various components of the date
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Months are zero-indexed, so add 1
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    // Format the date and time
    var formattedDateTime = year + '-' + 
                            (month < 10 ? '0' + month : month) + '-' + 
                            (day < 10 ? '0' + day : day) + ' ' + 
                            (hours < 10 ? '0' + hours : hours) + ':' + 
                            (minutes < 10 ? '0' + minutes : minutes) + ':' + 
                            (seconds < 10 ? '0' + seconds : seconds);

    return formattedDateTime;
}

export function timestampToDateString(timestamp) {
    if (!timestamp) {
        return '';
    }

    const date = moment(timestamp).format("D MMM YYYY");

    return date;
}

export function getTimeAgo(timestamp) {
    if (!timestamp) return "Not Listed";
    const timeAgo = moment(timestamp).fromNow();
    return timeAgo;
}

export function getFirstElement(list) {
    if (!list || !Array.isArray(list) || list.length === 0) {
        return null;
    }

    return list[0];
}

export async function separateFilesAndUrls(array) {
    const files = [];
    const urls = [];
    if (array) {
        array.forEach((item) => {
            if (item instanceof File) {
                files.push(item);
            } else if (typeof item === 'string') {
                urls.push(item);
            }
        });
    }
    return { files, urls };
}

export function removeCardById(board, id) {
    if (!board) {
        return {}; // or throw new Error("Board is null or undefined.");
    }
    let newColumns = board.columns.map(column => {
        let newCards = column.cards.filter(card => card.id !== id);
        return { ...column, cards: newCards };
    });
    return { ...board, columns: newColumns };
}


export function convertListingsToCards(listings) {
    let initialBoard = {
        columns: [
            {
                id: 1,
                title: "Inactive",
                cards: []
            },
            // {
            //     id: 2,
            //     title: "Pending Review",
            //     cards: []
            // },
            {
                id: 2,
                title: "Active",
                cards: []
            },
            {
                id: 3,
                title: "Archived",
                cards: []
            }
        ]
    };


    if (!listings || listings.length === 0) return initialBoard;

    // Iterate through each listing and add it to the appropriate card
    listings.forEach(listing => {
        if (listing !== null) {
            let card = {
                id: listing._id,
                submissionDate: `${timestampToDateString(listing.submissionDate)}`,
                title: `${listing.title}`,
                category: `${listing.category}`,
                image: getFirstElement(listing.images),
                ...listing
            };
            let listingStatus = listing && listing.status ? listing.status.toUpperCase() : "";
            switch (listingStatus) { // these are statuses from the api response
                case "NEW":
                case "REJECTED":
                case "CHANGES REQUIRED":
                    initialBoard.columns[0].cards.push(card);
                    break;
                // case "PENDING":
                //     initialBoard.columns[1].cards.push(card);
                //     break;
                case "APPROVED":
                    initialBoard.columns[1].cards.push(card);
                    break;
                case "SOLD":
                    initialBoard.columns[2].cards.push(card);
                    break;
                // Add additional cases for any other status values
                default: console.log(listing.status);
            }
        }
    });
    return initialBoard;
}
export function isPlanEligibleToPostToAllSchools(subDetails) {
    if (!subDetails) return false;
    var plan = subDetails.plan;
    if (plan === "ENTERPRISE" || plan === "MEDIUM_BUSINESS") return true;
    return false;
}

export function getMaxSchoolsEligible(subDetails) {
    if (!subDetails) return false;
    var plan = subDetails.plan;
    if (plan === "SMALL_BUSINESS") return process.env.REACT_APP_SCHOOLS_LIMIT_SMALL;
    return 2000; // arbitrary large number
}

export function isPremium(subDetails) {
    if (!subDetails) return false;
    var status = subDetails.status;
    var endDate = subDetails.endDate;
    if (status == "ACTIVE") {
        return true;
    } else if (status == "CANCELLED") {
        return isDateAfterToday(endDate) ? false : true;
    }
    return false;
}

export function isFreeTrial(subDetails) {
    if (!subDetails) return false;
    var plan = subDetails.plan;
    var endDate = subDetails.endDate;
    if (plan == "FREE_TRIAL") {
        const isDateAfter = isDateAfterToday(endDate) ? false : true;
        return true && isDateAfter;
    }
    return false;
}

export function isDateAfterToday(dateString) {
    if (!dateString) {
        return false;
    }

    const inputDate = moment(dateString, 'MM/DD/YYYY');

    const now = moment();

    //console.log('Input date:', inputDate.format('MM/DD/YYYY'));
    //console.log('Current date:', now.format('MM/DD/YYYY'));
    return now.isAfter(inputDate);
}




export function setJwtCookieWithTimeout(token) {
    return new Promise((resolve, reject) => {
        Cookies.set('SOUK_TOKEN', token);
        setTimeout(() => {
            const cookieValue = Cookies.get('SOUK_TOKEN');
            if (cookieValue === token) {
                resolve();
            } else {
                reject(new Error('Cookie was not set'));
            }
        }, 1000); // wait for 1 second
    });
}

export function generateSelectedSchoolDropDowns(data) {
    if (!data) return [];
    return data.map(obj => {
        return {
            logoUrl: obj,
            school: obj,
            label: obj,
            value: obj
        };
    });
}

export function generateDropDownSchools(isLoading, isPlanEligible, data) {
    if (!data && !isLoading) return [];
    if (isLoading) {
        return [{ school: "All", label: "All", value: "All", logoUrl: goldenStar }];
    } else if (isPlanEligible) {
        return [{ school: "All", label: "All", value: "All", logoUrl: goldenStar }, ...data.map(obj => {
            return {
                logoUrl: obj.logoUrl,
                school: obj.school,
                label: obj.school,
                value: obj.school
            };
        })];
    } else {
        return data.map(obj => {
            return {
                logoUrl: obj.logoUrl,
                school: obj.school,
                label: obj.school,
                value: obj.school
            };
        });
    }
}

export function getStageMessage(status) {
    if (!status) {
        return 'The status of this item is unknown.';
    }
    switch (status.toUpperCase()) {
        case 'NEW':
            return 'Ready for submission';
        case 'APPROVED':
            return 'Active';
        case 'CHANGES_REQUIRED':
        case 'REJECTED':
            return 'Requires Edits';
        case 'PENDING':
            return 'Submitted';
        case 'SOLD':
            return 'Sold';
        default:
            return 'Unknown';
    }
}
export function getStageBarColor(status) {
    if (!status) return '#00000';

    switch (status.toUpperCase()) {

        case 'NEW':
        case 'REJECTED':
            return '#044AFF';
        case 'PENDING':
            return '#FFB800';
        case 'APPROVED':
            return '#00FF19';
        case 'SOLD':
            return '#FF00E5';
        default:
            return '#00000';
    }
}

export function removeJwtCookie() {
    Cookies.remove('SOUK_TOKEN');
}

export function populateSalesChartData(data) {
    const totalImpressions = [];

    if (!data) {
        return totalImpressions;
    }

    data.forEach((item) => {
        const monthImpressions = item?.sales;
        if (monthImpressions) {
            totalImpressions.push(monthImpressions);
        } else {
            totalImpressions.push(0);
        }
    });

    return totalImpressions;
};

export function calculateTotalSales(monthSalesList) {
    let totalSales = 0;
    if (!monthSalesList || !monthSalesList.length) {
        return 0;
    }
    monthSalesList.forEach(monthSales => {
        totalSales += monthSales.sales;
    });

    return totalSales;
}

export function calculateTotalImpressions(monthSalesList) {
    let totalImpressions = 0;
    if (!monthSalesList || !monthSalesList.length) {
        return 0;
    }
    monthSalesList.forEach(monthSales => {
        totalImpressions += monthSales.totalImpressions;
    });

    return totalImpressions;
}

export function getAbbreviatedMonthNames(data) {
    const monthAbbreviations = [];

    if (!data) {
        return monthAbbreviations;
    }

    data.forEach((item) => {
        const month = item?.month?.toUpperCase();
        if (month) {
            monthAbbreviations.push(month.slice(0, 3));
        }
    });

    return monthAbbreviations;
}

export function populateImpressionsChartData(data) {
    const totalImpressions = [];

    if (!data) {
        return totalImpressions;
    }

    data.forEach((item) => {
        const monthImpressions = item?.totalImpressions;
        if (monthImpressions) {
            totalImpressions.push(monthImpressions);
        } else {
            totalImpressions.push(0);
        }
    });

    return totalImpressions;
}


export function buildTasksTable(tasks) {
    if (!tasks) return [];
    return tasks.map(task => {

        return {
            name: [task.name || '', "n/a"],
            email: task.email || '',
            status: task.status || '',
            classYear: [task.classYear || 'n/a'],
            joinedOn: [task.joinedOn || 'n/a']
        };
    });
}

export function buildAllowedUsersTable(tasks) {
    if (!tasks) return [];
    return tasks.map(task => {

        return {
            name: task.name || '',
            email: task.email || '',
            acceptedInvite: task.acceptedInvite,
            office: task.office || '',
            role: task.role || '',

        };
    });
}

export function buildAnnouncementsTable(tasks) {
    if (!tasks) return [];
    return tasks.map(task => {

        return {
            title: task.title || '',
            body: task.body || '',
            issuer: task.issuer || '',
            jobStatus: task.jobStatus || '',
            recipientsNumber: task.recipientsNumber || '',
            sentOn: task.sentOn || 'n/a',
        };
    });
}

export function buildThreadsTable(threads) {
    if (!threads) return [];
    return threads.map(thread => {
        return {
            threadId: thread.threadId || '',
            school: thread.school || '',
            schoolLogo: thread.schoolLogo || '',
            email: thread.email || '',
            firstName: thread.firstName || '',
            lastName: thread.lastName || '',
            threadTitle: thread.threadTitle || '',
            threadImage: thread.threadImage || '',
            numvotes: thread.numvotes || 0,
            views: thread.views || 0,
            isAnnouncement: thread.isAnnouncement || false,
            isAnonymous: thread.isAnonymous || false,
            longitude: thread.longitude || 0,
            latitude: thread.latitude || 0,
            comments: thread.comments || [],
            topics: thread.topics || [],
            createdOn: new Date(thread.createdOn) || new Date()
        };
    });
}


export function isSameDate(date1, date2) {
    if (!date1 || !date2) {
        return false;
    }
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}