/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Spinner,
  FormErrorMessage,
  InputGroup,
  Textarea,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";

import { Box, Flex, Button, Text, SimpleGrid, useDisclosure } from "@chakra-ui/react";
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Stack } from "@chakra-ui/react";
import { useCreateTaskMutation, useGetUserQuery, useGetTasksQuery } from "data/slices/users/usersSlice";


// Custom components



import React, { useState, useEffect } from 'react';


import AnnouncementTable from "views/admin/dataTables/components/AnnouncementTable";
import {
  columnsDataCheck,
} from "views/admin/announcement/variables/columnsData.js";

import { buildAnnouncementsTable, buildTasksTable, isPremium } from '../../../util/util'
import { useGetAnnouncementsQuery } from "data/slices/announcement/announcementSlice";
import { useSendAnnouncementMutation } from "data/slices/announcement/announcementSlice";
import moment from "moment";
import StripeModalPopup from "components/sidebar/components/StripeModalPopup";
import { useGetSubDetailsQuery } from "data/slices/subscription/subscription";
export default function Tasks() {
  const [sendAnnouncement, { isLoading: isSendingAnnouncement, error: error, data: data }] = useSendAnnouncementMutation();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()
  const [notification, setNotification] = useState(null);



  const { data: userData, isLoading: isLoadingUser, isSuccess: isSuccessUser, isError: isErrorUser, error: userError } = useGetUserQuery();
  const { data: tasks, isLoading: isLoadingTasks, isSuccess: isSuccessTasks, isError: isErrorTasks, error: tasksError, refresh } = useGetAnnouncementsQuery(userData?.school);
  const [annoucement, setSelectedAnnoucement] = useState(null);

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { isOpen: isOpenPricing, onOpen: onOpenPricing, onClose: onClosePricing } = useDisclosure()
  const { data: subDetails, error: subError, isLoading: isLoadingSub } = useGetSubDetailsQuery(userData?.email);

  const isPremiumAccount = isPremium(subDetails);
  const handleClose = () => {
    // Set the button's background color here
    // Replace "#A3F579" with your desired color
    document.getElementById("createTaskButton").style.backgroundColor = "#A3F579";
  };
  useEffect(() => {
    // Code to execute when 'tasks' value changes
    console.log('Announcements have changed:', tasks);

  }, [tasks, annoucement]);
  if (isLoadingUser || isLoadingTasks) {
    return (<Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      height='100vh'
    >
      <Spinner size='xl' />
    </Box>)
  }


  const handleSendNotification = async () => {
    if (!isPremiumAccount) {
      onOpenPricing();
      onClose();
      setIsConfirmOpen(false)
      setNotification(null)
      return;
    }
    if (notification) {
      try {
        const newTask = {
          title: notification.title,
          body: notification.body,
          issuer: notification.email,
          sentOn: notification.scheduledOn ? notification.scheduledOn : moment.now(),
          jobStatus: notification.scheduledOn ? "SCHEDULED" : "IN PROGRESS"
        };

        setSelectedAnnoucement(prevData => {
          if (Array.isArray(prevData)) {
            return [...prevData, newTask];
          } else {
            return [newTask]; // Handle case where prevData is not an array
          }
        });        // tasks.push({
        //   title: notification.title,
        //   body: notification.body,
        //   issuer: notification.email,
        //   sentOn: "now",
        //   jobStatus: "IN PROGRESS"
        // });

        // userTasks = buildAnnouncementsTable([...tasks, newTask]);
        await sendAnnouncement(notification);
      } catch (error) {

        console.error("Failed to delete comment:", error);
      }
      onClose();
      setIsConfirmOpen(false)
      setNotification(null)
    }
  };


  const getCurrentDateTimeString = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <StripeModalPopup
        isOpen={isOpenPricing}
        onOpen={onOpenPricing}
        onClose={onClosePricing}
      />
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={"black"}
          fontSize="24"
          fontWeight={"400"}
          lineHeight='100%'>
          <ChevronDownIcon />
          Campus Announcements
        </Text>
        <Modal isOpen={isOpen} onClose={handleClose}>
          <ModalOverlay />
          <Formik
            initialValues={{
              email: userData.email,
              school: userData.school,
              title: '',
              body: '',
              category: '',
              listingId: '',
              scheduledOn: null,
              priority: 'NONE',
              data: '',
            }}
            onSubmit={(values) => {
              try {
                setNotification(values);
                setIsConfirmOpen(true);
              } catch (error) {
                console.log(error);
              } finally {
                handleClose();
              }
            }}
          >
            {(formikProps) => (
              <Form>
                <ModalContent borderRadius="20px" width={{ base: "90%", sm: "80%", md: "60%" }} p={6}>
                  <ModalBody>
                    <Flex direction="column" gap={4}>
                      <Box>
                        <ModalHeader p={0}>Notification Title</ModalHeader>
                        <Field name="title">
                          {({ field, form }) => (
                            <InputGroup size='md'>
                              <Input
                                {...field}
                                isRequired={true}
                                placeholder="Title of notification"
                                borderRadius="20px"
                                size="md"
                              />
                              <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                            </InputGroup>
                          )}
                        </Field>
                      </Box>

                      <Box>
                        <ModalHeader p={0}>Notification Body</ModalHeader>
                        <Field name="body">
                          {({ field, form }) => (
                            <InputGroup>
                              <Textarea
                                {...field}
                                placeholder="Body of notification"
                                borderRadius="20px"
                                size="md"
                                maxLength={100}
                                maxW="100%"
                                maxH="120px"
                              />
                              <FormErrorMessage>{form.errors.body}</FormErrorMessage>
                            </InputGroup>
                          )}
                        </Field>
                      </Box>

                      <Box>
                        <ModalHeader p={0}>Redirect URL</ModalHeader>
                        <Field name="data">
                          {({ field, form }) => (
                            <InputGroup>
                              <Input
                                {...field}
                                placeholder="Optional data"
                                borderRadius="20px"
                                size="md"
                              />
                              <FormErrorMessage>{form.errors.data}</FormErrorMessage>
                            </InputGroup>
                          )}
                        </Field>
                      </Box>

                      <Box>
                        <ModalHeader p={0}>Priority</ModalHeader>
                        <Stack direction="row" spacing={4} align="center">
                          <Button
                            borderRadius="50px"
                            variant="solid"
                            w="200px"
                            h="10"
                            colorScheme={formikProps.values.priority === 'NONE' ? 'green' : 'gray'}
                            fontSize="16px"
                            fontWeight="400"
                            onClick={() => formikProps.setFieldValue('priority', 'NONE')}
                          >
                            No Priority
                          </Button>
                          <Button
                            borderRadius="50px"
                            variant="solid"
                            w="200px"
                            h="10"
                            colorScheme={formikProps.values.priority === 'HIGH' ? 'green' : 'gray'}
                            fontSize="16px"
                            fontWeight="400"
                            onClick={() => formikProps.setFieldValue('priority', 'HIGH')}
                          >
                            High Priority
                          </Button>
                        </Stack>
                      </Box>

                      {formikProps.values.priority !== 'HIGH' && (
                        <Box width="100%">
                          <ModalHeader p={0}>Send On</ModalHeader>
                          <Field name="scheduledOn">
                            {({ field, form }) => (
                              <InputGroup width="100%">
                                <Input
                                  {...field}
                                  min={getCurrentDateTimeString()}
                                  isRequired={true}
                                  placeholder="Select Date and Time"
                                  size="md"
                                  type="datetime-local"
                                  fontSize="sm"
                                  width="100%"
                                />
                                <FormErrorMessage>{form.errors.scheduledOn}</FormErrorMessage>
                              </InputGroup>
                            )}
                          </Field>
                        </Box>
                      )}
                    </Flex>
                  </ModalBody>

                  <ModalFooter>
                    <Flex width="100%" justify="space-between">
                      <Button
                        borderRadius="full"
                        onClick={() => {
                          onClose();
                          setNotification(null);
                        }}
                        size="lg"
                        bg={"transparent"}
                        color="black"
                        _hover={{ bg: "gray.400" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        isLoading={isSendingAnnouncement}
                        borderRadius="full"
                        size="lg"
                        bg="green.400"
                        color="white"
                        _hover={{ bg: 'green.500' }}
                      >
                        Send
                      </Button>
                    </Flex>
                  </ModalFooter>
                </ModalContent>
              </Form>
            )}
          </Formik>

        </Modal>
        <AlertDialog
          isOpen={isConfirmOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => setIsConfirmOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader alignText="center" fontSize="lg" fontWeight="bold">
                Campus Alert
              </AlertDialogHeader>
              <AlertDialogBody>
                You are about to send notification the following push notification to the entire campus:
              </AlertDialogBody>
              <AlertDialogBody>
                title: {notification ? notification.title : ""}
              </AlertDialogBody>
              <AlertDialogBody>
                body: {notification ? notification.body : ""}
              </AlertDialogBody>
              <AlertDialogBody>
                Your notification is scheduled to be sent {`${notification && notification.scheduledOn ? notification.scheduledOn : "instantly"}`}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={() => setIsConfirmOpen(false)}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={handleSendNotification}
                  ml={3}
                  isLoading={false}
                >
                  Confirm
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <Button
          id="createTaskButton"
          borderRadius="50px"
          variant="brand"
          color="black"
          background="#A3F579"
          ms="80px"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)"
          fontSize="16px" // Smaller font size
          fontWeight="700"
          onClick={onOpen}
          _hover={{ background: "#95dd6e" }}
          _active={{ color: "#95dd6e" }}
          outline="none"
          px="24px" // Padding for better fit
          py="12px" // Padding for better fit
        >
          Create Campus Notification
        </Button>

      </Flex>
      <SimpleGrid>
        <AnnouncementTable columnsData={columnsDataCheck} tableData={annoucement ? [...tasks, annoucement] : tasks} />
      </SimpleGrid>
    </Box>
  );
}

