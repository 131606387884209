// Chakra imports
// Custom components
import {
    Stack,
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
    FormErrorMessage,
    Select,
    InputLeftElement,
    Textarea,
    Avatar,
    List,
    ListItem,
    Alert,
} from "@chakra-ui/react";
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import { Formik, Form, Field } from "formik";
import Card from "components/card/Card.js";
import React, { useState } from "react";
// import { Select } from "chakra-react-select";
import { PhoneIcon, AddIcon, WarningIcon, CheckIcon, LinkIcon, EmailIcon, EditIcon, Search2Icon } from '@chakra-ui/icons'
// Assets
import { MdUpload } from "react-icons/md";
import { FaBath, FaBed } from "react-icons/fa";

import Dropzone from "views/admin/listing/components/Dropzone";
import { DatePicker } from 'chakra-ui-date-input'
import GooglePlacesInput from "../components/GooglePlaceInput";

import { useGetSchoolsDataQuery } from "../../../../data/slices/listings/listingsslice";
import { generateDropDownSchools } from "../../../../util/util";
import goldenStar from "assets/img/auth/golden_star.png";

export default function Upload(props) {
    const { used, total, handleSubmit, isDisabled, isPlanEligibleToPostToAllSchools, maxSchoolsListingLimit, ...rest } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const brandColor = useColorModeValue("brand.500", "white");
    const textColorSecondary = "gray.400";
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const [moveInDate, setMoveInDate] = useState(null);
    const [numBath, setNumBath] = useState(null);

    const [schoolInputValue, setSchoolInputValue] = useState('');
    const { data, isLoading, isSuccess, isError, error } = useGetSchoolsDataQuery(schoolInputValue);
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [schoolErrorMsg, setSchoolErrorMsg] = React.useState(null);
    const [addressErrorMsg, setAddressErrorMsg] = React.useState(null);

    React.useEffect(() => {
        const housingDraft = JSON.parse(localStorage.getItem('housingDraft'));
        if (housingDraft && housingDraft.schools) {
            const existingSelection = housingDraft.schools.map((value) => ({
                school: value,
                label: value,
                value: value,
                logoUrl: goldenStar // Assuming "goldenStar" is a string value
            }));
            setSelectedItems(existingSelection);
        }

    }, []);

    const handleSelectedItemsChange = (newSelectedItems, setFieldValue) => {
        if (newSelectedItems && newSelectedItems.length <= maxSchoolsListingLimit) {
            const hadAllValue = selectedItems ? selectedItems.some(item => item.school === "All") : false;
            const hasAllValue = newSelectedItems.some(item => item.school === "All");
            var newList = hasAllValue && !hadAllValue && isPlanEligibleToPostToAllSchools ? [{ school: "All", label: "All", value: "All", logoUrl: goldenStar }] : newSelectedItems.filter(item => item.school !== "All" || item.label !== "All");
            setSelectedItems(newList);
            setFieldValue('schools', newList ? newList.map(obj => obj.school ? obj.school : obj.label) : null);
            setSchoolErrorMsg(null);
        } else {
            setSchoolErrorMsg("You have reached the maximum number of schools allowed on your plan!");
        }
    };

    const handleChangeMoveInDate = async (setFieldValue, date) => {
        await setFieldValue("moveInDate", date);
        await setMoveInDate(date)
    };

    const handleSchoolInputChange = async (event) => {
        if (event.target.value) {
            setSchoolInputValue(event.target.value);
            // setItems(schoolsData);
        } else {
            setSchoolInputValue('');
            // setItems(schoolsData);

        }
    };



    const customRender = (selected) => {
        return (
            <Flex flexDir="row" alignItems="center">

                {selected.logoUrl ? <Avatar mr={2} size="sm" src={selected.logoUrl} /> : <Avatar mr={2} size="sm" name={selected.school} />}
                <Text>{selected.school}</Text>
            </Flex>
        )
    }

    const getInitialValues = () => {
        const housingDraft = localStorage.getItem('housingDraft');

        try {
            if (housingDraft) {
                const parsedDraft = JSON.parse(housingDraft);
                parsedDraft.type = "Housing Arrangements";
                return parsedDraft;
            }
        } catch (error) {
            console.error('Error parsing housingDraft:', error);
        }

        return {
            title: null,
            price: null,
            placeId: null,
            address: null,
            type: "Housing Arrangements",
            // schools: null,
            email: null,
            phone: null,
            description: null,
            housingType: "University Housing",
            distanceAwayFromCampus: null,
            leaseType: null,
            numBedrooms: null,
            numBathrooms: null,
            moveInDate: null,
            usefulLink: null
        };
    };


    const handleFormChange = async (values) => {
        await localStorage.setItem('housingDraft', JSON.stringify(values));
    };

    return (
        <Card
            borderTopRightRadius='20px'
            borderBottomRightRadius='20px'
            direction='column'
            px='25px'
            overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Formik
                enableReinitialize={true}
                initialValues={getInitialValues()}
                onSubmit={async (values, { setSubmitting, setErrors, setStatus, resetForm, setValues }) => {
                    /**
                     * this is a temporary fix
                     * for some reason props.setFieldValue does not set values if thhe array is one element?
                     * 
                     */

                    if (!values.moveInDate) values.moveInDate = moveInDate;
                    if (!values.numBathrooms) values.numBathrooms = numBath;

                    // if (!values.moveInDate)values.moveInDate = moveInDate;

                    // if (selectedItems && (!values.schools || values.schools.length == 0)) {
                    //     values.schools = selectedItems.map(obj => obj.school ? obj.school : obj.label);
                    // }
                    // const doesNotHaveSchoolValue = !values.schools || values.schools.length == 0;
                    try {
                        if (false) {
                            setSchoolErrorMsg('You must select at least one school');
                            return;
                        } else if (!values.address) {
                            setAddressErrorMsg('Please specify a valid address');
                            return;
                        } else {
                            await handleSubmit(values, setSelectedItems);
                            setSchoolErrorMsg(null);
                            setAddressErrorMsg(null);
                        }
                    } catch (error) {
                        setStatus({ success: false })
                        setSubmitting(false)
                        setErrors({ submit: error.message })
                    }
                }}
            >
                {(props) => (
                    <Form style={{ overflow: 'hidden' }} onChange={async () => await handleFormChange(props.values)}>
                        <Field name="title" >
                            {({ field, form }) => (
                                <FormControl
                                >
                                    <FormLabel
                                        ms='4px'
                                        fontSize='sm'
                                        fontWeight='100'
                                        color={textColorPrimary}
                                        isRequired={false}
                                        display='flex'>
                                        Title
                                    </FormLabel>
                                    <InputGroup size='md'>
                                        <Input
                                            {...field} id="title"
                                            isRequired={false}
                                            variant='auth'
                                            fontSize='sm'
                                            maxLength={30}
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Enter title for your post'
                                            mb='12px' // | space
                                            width='95%'
                                            fontWeight='500'
                                            size='lg'
                                            type="link"
                                            isDisabled={props.isSubmitting || isDisabled}
                                        />
                                    </InputGroup>
                                </FormControl>
                            )}

                        </Field>
                        {addressErrorMsg ?
                            <Flex flexDirection={"column"} align='center' mb='10px'>
                                <Alert status='error'>
                                    {addressErrorMsg}
                                </Alert>
                            </Flex> : null}
                        <Stack direction='row' mt='10px'>
                            <Field name="price" >
                                {({ field, form }) => (
                                    <FormControl
                                    >
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'>
                                            Monthly Rent<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size='md'>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                color='gray.300'
                                                fontSize='1.2em'
                                                children='$'
                                                mt='3px'
                                            />
                                            <Input
                                                {...field} id="price"
                                                isRequired={true}
                                                fontSize='sm'
                                                placeholder='Enter amount'
                                                mb='24px'
                                                size='lg'
                                                type={"number"}
                                                variant='auth'
                                                w='90%'
                                                isDisabled={props.isSubmitting || isDisabled}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.price}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name="address" >
                                {/* Trouble in this line below*/}
                                {({ field, form }) => (
                                    <FormControl
                                    >
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'>
                                            Address<Text color={brandStars}>*</Text>
                                        </FormLabel>

                                        <GooglePlacesInput
                                            name={field.name}
                                            // onChange={field.onChange}
                                            value={field.value}
                                            onBlur={field.onBlur}
                                            id="address"
                                            isRequired={true}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            type='text'
                                            placeholder='Enter address'
                                            mb='24px' // | space
                                            fontWeight='500'
                                            size='lg'
                                            w='90%'

                                            isDisabled={props.isSubmitting || isDisabled}
                                            setFieldValue={props.setFieldValue} />
                                        <FormErrorMessage>
                                            {form.errors.address}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Stack>

                        <Stack direction='row'>
                            <Field name="email" >
                                {/* Trouble in this line below*/}
                                {({ field, form }) => (
                                    <FormControl
                                    >
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'>
                                            Contact Email<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size='md'>

                                            <InputLeftElement
                                                pointerEvents='none'
                                                children={<EmailIcon color='gray.300' mt='5px' />}
                                            />
                                            <Input
                                                {...field} id="email"
                                                isRequired={true}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                type='email'
                                                placeholder='mail@collegesouk.com'
                                                mb='24px'
                                                fontWeight='500'
                                                size='lg'
                                                w='90%'
                                                isDisabled={props.isSubmitting || isDisabled}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.email}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="phone" >
                                {/* Trouble in this line below*/}
                                {({ field, form }) => (
                                    <FormControl
                                    >
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={false}
                                            display='flex'>
                                            Phone Number<Text color={brandStars}></Text>
                                        </FormLabel>
                                        <InputGroup size='md'>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                children={<PhoneIcon color='gray.300' mt='5px' />}
                                            />
                                            <Input
                                                {...field} id="phone"
                                                isRequired={false}
                                                fontSize='sm'
                                                placeholder='555-556-5488'
                                                mb='24px'
                                                size='lg'
                                                type='tel'
                                                variant='auth'
                                                w='90%'
                                                isDisabled={props.isSubmitting || isDisabled}

                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.phone}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Stack>
                        {/* {schoolErrorMsg ?
                            <Flex flexDirection={"column"} align='center' mb='10px'>
                                <Alert status='error'>
                                    {schoolErrorMsg}
                                </Alert>
                            </Flex> : null}
                        <Stack direction='row'>

                            <Field name="schools" >
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.schools && form.touched.schools}>

                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'
                                            width='10rem'
                                        >
                                            School(s)<Text color={brandStars}>*</Text>
                                        </FormLabel>

                                        <CUIAutoComplete
                                            // disableCreateItem={true}

                                            inputStyleProps={{
                                                variant: 'auth',
                                                size: 'lg',
                                                w: '95%',
                                                fontSize: 'sm',
                                                fontWeight: '500',
                                                pointerEvents: (props.isSubmitting || isDisabled || (selectedItems && selectedItems.length > maxSchoolsListingLimit)) ? "none" : "auto"
                                            }}

                                            disableCreateItem={true}
                                            renderCustomInput={(inputProps) => (
                                                <InputGroup size="md">
                                                    <InputLeftElement
                                                        pointerEvents='none'
                                                        children={<Search2Icon color='gray.300' mt='5px' />}
                                                    />
                                                    <Input
                                                        {...field}
                                                        {...inputProps}
                                                        id="schools"
                                                        isRequired={selectedItems && selectedItems.length > 0 ? false : true}
                                                        onInput={handleSchoolInputChange}
                                                        placeholder='Search School(s)'
                                                    />
                                                </InputGroup>
                                            )}
                                            placeholder="Search for School"
                                            hideToggleButton={false}
                                            size="sm"
                                            items={generateDropDownSchools(isLoading, isPlanEligibleToPostToAllSchools, data)}
                                            itemRenderer={customRender}
                                            selectedItems={selectedItems}
                                            onSelectedItemsChange={async (changes) => {
                                                await handleSelectedItemsChange(changes.selectedItems, props.setFieldValue)
                                            }
                                            }

                                        />
                                        <FormErrorMessage>
                                            {form.errors.schools}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>


                        </Stack> */}
                        <Stack direction='row'>
                            <Field name="housingType" >
                                {/* Trouble in this line below*/}
                                {({ field, form }) => (
                                    <FormControl
                                    >
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'>
                                            Housing Type<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size='md'>

                                            <Select
                                                {...field}
                                                id="housingType"
                                                isRequired={true}
                                                fontSize='sm'
                                                placeholder='Select Housing Type'
                                                mb='24px'
                                                size='lg'
                                                variant='auth'
                                                defaultValue={"University Housing"}
                                                disabled
                                                isDisabled={props.isSubmitting || isDisabled}
                                                w='95%'
                                            >
                                                <option value="All">All</option>
                                                <option value="University Housing">University Housing</option>
                                                <option value="Full House (Off Campus)">Full House (Off Campus)</option>
                                                <option value="Apartments (Off Campus)">Apartments (Off Campus)</option>
                                                <option value="Rooms (Off Campus)">Rooms (Off Campus)</option>
                                            </Select>
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.housingType}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Stack>
                        <Stack direction='row'>
                            <Field name="distanceAwayFromCampus" >
                                {/* Trouble in this line below*/}
                                {({ field, form }) => (
                                    <FormControl
                                    >
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'>
                                            Distance Away From Campus<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size='md'>

                                            <Select
                                                w='90%'
                                                {...field}
                                                id="distanceAwayFromCampus"
                                                placeholder="Select Distance"
                                                isRequired={true}
                                                fontSize='sm'
                                                mb='24px'
                                                size='lg'
                                                variant='auth'
                                                isDisabled={props.isSubmitting || isDisabled}
                                            >
                                                <option value="Less than 5 minutes">Less than 5 minutes</option>
                                                <option value="5 - 15 minutes">5 - 15 minutes</option>
                                                <option value="10 - 20 minutes">10 - 20 minutes</option>
                                                <option value="More than 30 minutes">More than 30 minutes</option>
                                            </Select>
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.distanceAwayFromCampus}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="leaseType" >
                                {/* Trouble in this line below*/}
                                {({ field, form }) => (
                                    <FormControl
                                    >
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={false}
                                            display='flex'>
                                            Lease Type<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size='md'>

                                            <Select
                                                w='90%'
                                                {...field}
                                                id="leaseType"
                                                isRequired={false}
                                                fontSize='sm'
                                                placeholder='Select lease type'
                                                mb='24px'
                                                size='lg'
                                                variant='auth'
                                                isDisabled={props.isSubmitting || isDisabled}
                                            >
                                                <option value="All">All</option>
                                                <option value="Sublease">Sublease</option>
                                                <option value="Regular Lease">Regular Lease</option>
                                                <option value={null}>Not Applicable</option>
                                            </Select>
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.leaseType}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                        </Stack>
                        <Stack direction='row'>

                            <Field name="numBedrooms" >
                                {/* Trouble in this line below*/}
                                {({ field, form }) => (
                                    <FormControl
                                    >
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'>
                                            Number of Bedrooms<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size='md'>

                                            <InputLeftElement
                                                pointerEvents='none'
                                                children={<FaBed color='#cbd5e0'
                                                // move 

                                                />}
                                            />
                                            <Input
                                                w='90%'
                                                {...field} id="numBedrooms"
                                                isRequired={true}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                type='number'
                                                placeholder='Enter number bed'
                                                mb='24px'
                                                fontWeight='500'
                                                size='lg'
                                                isDisabled={props.isSubmitting || isDisabled}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.numBedrooms}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="numBathrooms" >
                                {/* Trouble in this line below*/}
                                {({ field, form }) => (
                                    <FormControl
                                    >
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'>
                                            Number of Bathrooms<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size='md'>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                // make the color of the icon a light gray
                                                children={<FaBath color='#cbd5e0' />}
                                            />
                                            <Input
                                                w='90%'
                                                {...field} id="numBathrooms"
                                                isRequired={true}
                                                onChange={event => setNumBath(event.target.value)}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                type='number'
                                                placeholder='Enter number bathrooms'
                                                mb='24px'
                                                fontWeight='500'
                                                size='lg'
                                                isDisabled={props.isSubmitting || isDisabled}
                                            />


                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.numBathrooms}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Stack>
                        <Stack spacing={5} direction='row'>
                            <Field name="moveInDate" >

                                {({ field, form }) => (
                                    <FormControl
                                    >
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'>
                                            Move-in date<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size='md' w='91%'>

                                            <DatePicker
                                                {...field}
                                                id="moveInDate"
                                                isRequired={true}
                                                fontSize='sm'
                                                placeholder='Select move-in date'
                                                mb='24px'
                                                size='lg'
                                                isDisabled={props.isSubmitting || isDisabled}
                                                defaultChecked={moveInDate}
                                                defaultValue={moveInDate}
                                                onChange={(date) => {
                                                    // await handleChangeMoveInDate(props.setFieldValue, date);
                                                    // await props.setFieldValue("moveInDate", date);
                                                    setMoveInDate(date);
                                                }}

                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.moveInDate}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="usefulLink" >

                                {({ field, form }) => (
                                    <FormControl
                                    >
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={false}
                                            display='flex'>
                                            Relevant Link<Text color={brandStars}></Text>
                                        </FormLabel>
                                        <InputGroup size='md'>

                                            <InputLeftElement
                                                pointerEvents='none'
                                                children={<LinkIcon color='gray.300' />}
                                            />
                                            <Input
                                                w='90%'
                                                {...field} id="usefulLink"
                                                isRequired={false}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                placeholder='Optional'
                                                mb='12px' // | space
                                                fontWeight='500'
                                                size='lg'
                                                type="link"
                                                isDisabled={props.isSubmitting || isDisabled}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.usefulLink}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Stack>
                        <Flex w='95%'>
                            <Button
                                fontSize='lg'
                                fontWeight='semibold'
                                variant='brand'
                                color='black'
                                w='220px'
                                h='50px'
                                mt='2.5rem'
                                mb='2.5rem'
                                isDisabled={props.isSubmitting || isDisabled}
                                isLoading={props.isSubmitting}
                                type="submit"
                                background='#A3F579'
                                ms='34%'
                                boxShadow='0px 4px 4px rgba(0, 0, 0, 0.15)'
                                borderRadius='50px'
                                _hover={{ background: '#95dd6e' }}>
                                Create Listing
                            </Button>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </Card>
    );
}
