import React, { useMemo, useState, useEffect } from "react";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Flex,
    Text,
    Image,
    Button,
    IconButton,
    Icon,
    ButtonGroup,
    Box,
    SimpleGrid,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Divider,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { MdComment, MdEdit, MdDelete } from "react-icons/md";
import Card from "components/card/Card";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useDeleteCommentMutation, useDeleteThreadMutation } from "data/slices/threads/threadsSlice";
import { useDeleteStudentListingMutation } from "data/slices/listings/listingsslice";
import StripeModalPopup from "components/sidebar/components/StripeModalPopup";

export default function MarketplaceTable({ columnsData, tableData, schoolData, isPremiumAccount }) {
    // Log props to ensure they are not undefined

    const columns = useMemo(() => columnsData || [], [columnsData]);
    const data = useMemo(() => tableData || [], [tableData]);
    const [cellData, setCellData] = useState(data);

    useEffect(() => {
        if (!columnsData || !tableData) {
            console.error("columnsData or tableData is undefined");
        }
    }, [columnsData, tableData]);

    const tableInstance = useTable(
        {
            columns,
            data: cellData,
            initialState: { pageSize: 9 }, // 3 rows per page
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex, pageSize },
        gotoPage,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        pageCount,
    } = tableInstance;

    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isItemDeleted, setIsItemDeleted] = useState(false);
    const [isDeleteCommentDialogOpen, setIsDeleteCommentDialogOpen] = useState(false);
    const [isDeleteThreadDialogOpen, setIsDeleteThreadDialogOpen] = useState(false);
    const [commentToDelete, setCommentToDelete] = useState(null);
    const cancelRef = React.useRef();
    const [deleteComment, { isLoading: isDeletingComment }] = useDeleteCommentMutation();
    const [deleteThread, { isLoading: isDeletingThread }] = useDeleteStudentListingMutation();
    const { isOpen: isOpenPricing, onOpen: onOpenPricing, onClose: onClosePricing } = useDisclosure()

    const handleManageClick = (item) => {
        if (!isPremiumAccount) {
            onOpenPricing();
        } else {
            setSelectedItem(item);
            setIsModalOpen(true);
        }
    };

    const handleOpenDeleteCommentDialog = (index) => {
        setCommentToDelete(index);
        setIsDeleteCommentDialogOpen(true);
    };

    const handleDeleteComment = async () => {
        try {
            const updatedComments = [...selectedItem.comments];
            const [removedComment] = updatedComments.splice(commentToDelete, 1);

            // Call the mutation to delete the comment
            await deleteComment(removedComment.commentId, selectedItem.itemId);

            setSelectedItem({ ...selectedItem, comments: updatedComments });

            // Update the cell data with the new comment count
            const updatedCellData = cellData?.map(item =>
                item.itemId === selectedItem.itemId
                    ? { ...item, comments: updatedComments }
                    : item
            );
            setCellData(updatedCellData);

            setIsDeleteCommentDialogOpen(false);
        } catch (error) {
            console.error("Failed to delete comment:", error);
        }
    };

    const handleOpenDeleteThreadDialog = () => {
        setIsDeleteThreadDialogOpen(true);
    };

    const handleDeleteItem = async () => {
        try {
            // Call the mutation to delete the item
            await deleteThread(selectedItem.itemId);

            // Remove the item from the data
            const updatedCellData = cellData.filter(item => item.itemId !== selectedItem.itemId);
            setCellData(updatedCellData);

            setSelectedItem(null);
            setIsModalOpen(false);
            setIsItemDeleted(true);
            setIsDeleteThreadDialogOpen(false);
        } catch (error) {
            console.error("Failed to delete item:", error);
        }
    };


    if (data && data.length == 0) {
        return (<Flex direction="row" mr="auto" mt={3} align="center">
            <Text color={"gray.600"} fontSize="md" fontWeight="medium" mr="2">
                No Listings Available
            </Text>
        </Flex>)
    }


    return (
        <Card direction="column" w="100%" px="0px" borderRadius="30px" overflowX={{ sm: "scroll", lg: "hidden" }}>
            <StripeModalPopup
                isOpen={isOpenPricing}
                onOpen={onOpenPricing}
                onClose={onClosePricing}
            />
            <SimpleGrid columns={3} spacing={5} marginRight={5} marginLeft={5}>
                {page?.map((row) => {
                    prepareRow(row);
                    return (
                        <Box key={row.id} borderWidth="1px" borderRadius="lg" overflow="hidden" position="relative">
                            <Carousel showThumbs={false} showStatus={false} infiniteLoop>
                                {row.original.images?.map((image, index) => (
                                    <Image key={index} src={image} w="100%" h="200px" objectFit="cover" />
                                ))}
                            </Carousel>
                            <Box p="6">
                                <Flex justifyContent="space-between" alignItems="center" mb="2">
                                    <Text
                                        color="gray.500"
                                        fontWeight="semibold"
                                        letterSpacing="wide"
                                        fontSize="xs"
                                        textTransform="uppercase"
                                    >
                                        {row.original.category}
                                    </Text>
                                    <Text fontSize="sm" color="gray.600">
                                        ${row.original.price}
                                    </Text>
                                </Flex>
                                <Text mt="1" fontWeight="semibold" as="h4" lineHeight="tight" noOfLines={2}>
                                    {row.original.title}
                                </Text>
                                <Box maxHeight="100px" overflowY="auto" mb="2"> {/* Constrained height and scrolling */}
                                    <Text color="gray.600" fontSize="sm" noOfLines={3}>
                                        {row.original.description}
                                    </Text>
                                </Box>
                                <Box mt={"10"}>
                                    <Button
                                        colorScheme="teal"
                                        size="sm"
                                        position="absolute"
                                        bottom="4"
                                        right="4"
                                        mt={"10"}
                                        onClick={() => handleManageClick(row.original)}
                                    >
                                        manage
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    );
                })}
            </SimpleGrid>





            <Flex justifyContent="center" mt="2">
                <ButtonGroup size="sm" isAttached variant="outline">
                    <IconButton
                        icon={<ChevronLeftIcon />}
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                        aria-label="Previous Page"
                    />
                    {Array.from({ length: pageCount }, (_, index) => (
                        <Button
                            key={index}
                            variant="outline"
                            colorScheme={pageIndex === index ? "teal" : undefined}
                            onClick={() => gotoPage(index)}
                        >
                            {index + 1}
                        </Button>
                    ))}
                    <IconButton
                        icon={<ChevronRightIcon />}
                        onClick={nextPage}
                        disabled={!canNextPage}
                        aria-label="Next Page"
                    />
                </ButtonGroup>
            </Flex>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Flex justify="center" align="center">
                            <Text fontSize="lg" fontWeight="bold">
                                {selectedItem?.title}
                            </Text>
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedItem && (
                            <>
                                <Carousel
                                    showThumbs={false}
                                    showStatus={false}
                                    infiniteLoop
                                    useKeyboardArrows
                                    dynamicHeight={false}
                                    style={{ maxHeight: '400px', marginBottom: '20px' }}
                                >
                                    {selectedItem.images?.map((image, index) => (
                                        <Image key={index} src={image} maxH="400px" objectFit="cover" />
                                    ))}
                                </Carousel>
                                <Box mb={4}>
                                    <Text fontSize="md" color="gray.600" mt={5} mb={2}>
                                        <b>Listing by:</b> {selectedItem.email}
                                    </Text>
                                    <Text fontSize="md" color="gray.600" mb={2}>
                                        <b>Category:</b> {selectedItem.category}
                                    </Text>
                                    <Text fontSize="md" color="gray.600" mb={2}>
                                        <b>Price:</b> ${selectedItem.price}
                                    </Text>
                                </Box>
                                {selectedItem.description && <>
                                    <Divider mb={4} />
                                    <Text fontSize="md" color="gray.600" mb={4}>
                                        {selectedItem.description}
                                    </Text>
                                </>}
                            </>
                        )}
                    </ModalBody>


                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={handleOpenDeleteThreadDialog}>
                            Remove Listing
                        </Button>
                        <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <AlertDialog
                isOpen={isDeleteCommentDialogOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsDeleteCommentDialogOpen(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Remove Listing
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to delete this listing?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setIsDeleteCommentDialogOpen(false)}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={handleDeleteComment}
                                ml={3}
                                isLoading={isDeletingComment}
                            >
                                Confirm
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <AlertDialog
                isOpen={isDeleteThreadDialogOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsDeleteThreadDialogOpen(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Permanent Change
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to delete this listing?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setIsDeleteThreadDialogOpen(false)}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={handleDeleteItem}
                                ml={3}
                                isLoading={isDeletingThread}
                            >
                                Confirm
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Card>
    );
}
