import React, { useMemo, useState, useEffect } from "react";
import {
    Divider,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    ButtonGroup,
    Icon,
    Button,
    IconButton,
    Alert,
    AlertIcon
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import { MdAccessTime } from 'react-icons/md'; // Importing a clock icon from react-icons

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { getTimeAgo, timestampToDateTime, buildAnnouncementsTable } from "util/util";

export default function AnnouncementTable(props) {
    const { columnsData, tableData } = props;
    const [data, setData] = useState(buildAnnouncementsTable(tableData));

    useEffect(() => {
        setData(buildAnnouncementsTable(tableData));
    }, [tableData]);

    const columns = useMemo(() => columnsData, [columnsData]);

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 15 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex, pageSize = 15 },
        gotoPage,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        pageCount,
    } = tableInstance;

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const getAlertComponent = (status, value) => {
        switch (status) {
            case 'SCHEDULED':
                return (
                    <Alert status='warning'>
                        <AlertIcon as={MdAccessTime} />
                        {value}
                    </Alert>
                );
            case 'FINISHED':
                return (
                    <Alert status='success'>
                        <AlertIcon />
                        {value}
                    </Alert>
                );
            case 'IN_PROGRESS':
                return (
                    <Alert status='info'>
                        <AlertIcon />
                        {"IN PROGRESS"}
                    </Alert>
                );
            case 'FAILED':
                return (
                    <Alert status='error'>
                        <AlertIcon />
                        {value}
                    </Alert>
                );
            default:
                return (
                    <Alert status='info'>
                        <AlertIcon />
                        {value}
                    </Alert>
                );
        }
    };
    return (
        <Card
            direction='column'
            w='100%'
            px='0px'
            borderRadius='30px'
            overflowX={{ sm: "scroll", lg: "hidden" }}
        >
            <Table {...getTableProps()} variant='striped' color='gray.500' mb='24px'>
                <Thead align='center'>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                                <Th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    pe='10px'
                                    key={index}
                                    borderColor='#959595'
                                >
                                    <Flex
                                        justify='space-between'
                                        align='center'
                                        fontSize={{ sm: "10px", lg: "12px" }}
                                        color='gray.400'
                                    >
                                        {column.render("Header")}
                                    </Flex>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()} key={index}>
                                {row.cells.map((cell, index) => {
                                    let cellContent = "";
                                    switch (cell.column.Header) {
                                        case "Title":
                                            cellContent = (
                                                <Flex align='center'>
                                                    <Text color={"black"} fontSize='sm' fontWeight='700'>
                                                        {cell.value}
                                                    </Text>
                                                </Flex>
                                            );
                                            break;
                                        case "Body":
                                            cellContent = (
                                                <Flex align='center'>
                                                    <Text maxWidth={300} color={"black"} noOfLines={2} fontSize='sm' fontWeight='700'>
                                                        {cell.value}
                                                    </Text>
                                                </Flex>
                                            );
                                            break;
                                        case "Issuer":
                                            cellContent = (
                                                <Text color="black" fontSize='sm' fontWeight='700'>
                                                    {cell.value}
                                                </Text>
                                            );
                                            break;
                                        case "Status":
                                            cellContent = getAlertComponent(cell.value, cell.value);
                                            break;
                                        case "Recipients":
                                            cellContent = (
                                                <Text color={"black"} fontSize='sm' fontWeight='700'>
                                                    {cell.value}
                                                </Text>
                                            );
                                            break;
                                        case "Date":
                                            cellContent = (
                                                <Text color={"black"} fontSize='sm' fontWeight='700'>
                                                    {timestampToDateTime(cell.value)}
                                                </Text>
                                            );
                                            break;
                                        default:
                                            break;
                                    }
                                    return (
                                        <Td
                                            {...cell.getCellProps()}
                                            key={index}
                                            fontSize={{ sm: "14px" }}
                                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                            borderColor='transparent'
                                        >
                                            {cellContent}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            {/* Pagination */}
            <Flex justifyContent='center' mt='2'>
                <ButtonGroup size='sm' isAttached variant='outline'>
                    <IconButton
                        icon={<Icon as={ChevronLeftIcon} />}
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                        aria-label='Previous Page'
                    />
                    {Array.from({ length: pageCount }, (_, index) => (
                        <Button
                            key={index}
                            variant='outline'
                            colorScheme={pageIndex === index ? 'blue' : undefined}
                            onClick={() => gotoPage(index)}
                        >
                            {index + 1}
                        </Button>
                    ))}
                    <IconButton
                        icon={<Icon as={ChevronRightIcon} />}
                        onClick={nextPage}
                        disabled={!canNextPage}
                        aria-label='Next Page'
                    />
                </ButtonGroup>
            </Flex>
        </Card>
    );
}
