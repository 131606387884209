import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdHome,
  MdAdd,
  MdNotifications,
  MdTask,
  MdHelp,
  MdMic,
  MdShoppingBag,
  MdOutlineHandshake,
  MdFlag,
  MdHandshake,
  MdHouse,
  MdEvent,
  MdSettings,
  MdEmojiPeople,
  MdAnnouncement
} from "react-icons/md";
import {
  FaListUl,
  FaServer,
  FaUsers,
  FaCode,
  FaTerminal,
  FaNetworkWired,
  FaPlug,
  FaHourglassStart
} from "react-icons/fa";


// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import Leads from "views/admin/leads";

import RTL from "views/admin/rtl";
import CreateListing from "views/admin/listing";
import Billing from "views/admin/billing";
import Listings from "views/admin/view";
import Support from "views/admin/support";
import Directory from "views/admin/directory";
import Threads from "views/admin/threads";
import Connect from "views/admin/connect";
import Announcements from "views/admin/announcement";
import Services from "views/admin/services";
import Housing from "views/admin/housing";
import Marketplace from "views/admin/marketplace";

import Notifications from "views/admin/notification";
import CRM from "views/admin/crm";

// Auth Imports


const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  //{
  //  name: "Support",
  //  layout: "/admin",
  //  path: "/support",
  //  icon: <Icon as={MdHelp} width='20px' height='20px' color='inherit' />,
  //  component: Support,
  // },
  // {
  //   name: "Notifications",
  //   layout: "/admin",
  //   path: "/notifications",
  //   icon: <Icon as={MdNotifications} width='20px' height='20px' color='inherit' />,
  //   component: Notifications,
  // },
  {
    name: "Marketplace Management",
    layout: "/admin",
    path: "/marketplace",
    icon: <Icon as={MdShoppingBag} width='20px' height='20px' color='inherit' />,
    component: Marketplace,
  },
  {
    name: "Services Management",
    layout: "/admin",
    path: "/services",
    icon: <Icon as={MdHandshake} width='20px' height='20px' color='inherit' />,
    component: Services,
  },
  {
    name: "Housing Management",
    layout: "/admin",
    path: "/housing",
    icon: <Icon as={MdHouse} width='20px' height='20px' color='inherit' />,
    component: Housing,
  },
  {
    name: "Connect Management",
    layout: "/admin",
    path: "/connect",
    icon: <Icon as={MdEmojiPeople} width='20px' height='20px' color='inherit' />,
    component: Connect,
  },
  {
    name: "Threads Management",
    layout: "/admin",
    path: "/threads",
    icon: <Icon as={MdMic} width='20px' height='20px' color='inherit' />,
    component: Threads,
  },
  // {
  //   name: "Events Management",
  //   layout: "/admin",
  //   path: "/events",
  //   icon: <Icon as={MdEvent} width='20px' height='20px' color='inherit' />,
  //   component: null,
  // },
  {
    name: "Souk Directory",
    layout: "/admin",
    icon: <Icon as={FaUsers} width='20px' height='20px' color='inherit' />,
    path: "/directory",
    component: Directory,
  },
  // {
  //   name: "Flags",
  //   layout: "/admin",
  //   icon: <Icon as={MdFlag} width='20px' height='20px' color='inherit' />,
  //   path: "/flags",
  //   component: null,
  // },
  {
    name: "Announcements",
    layout: "/admin",
    path: "/announcements",
    icon: <Icon as={MdAnnouncement} width='20px' height='20px' color='inherit' />,
    component: Announcements,
  },
  {
    name: "Account",
    layout: "/admin",
    path: "/account",
    icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
  {
    name: "My Listings",
    layout: "/admin",
    path: "/listings",
    icon: <Icon as={FaListUl} width='20px' height='20px' color='inherit' />,
    component: Listings,
  },
  {
    name: "Create Listing",
    layout: "/admin",
    path: "/create",
    icon: <Icon as={MdAdd} width='20px' height='20px' color='inherit' />,
    component: CreateListing,
  }
];

export default routes;
