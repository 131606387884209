export const columnsDataCheck = [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Body",
    accessor: "body",
  },
  {
    Header: "Issuer",
    accessor: "issuer",
  },
  {
    Header: "Status",
    accessor: "jobStatus",
  },
  {
    Header: "Recipients",
    accessor: "recipientsNumber",
  },
  {
    Header: "Date",
    accessor: "sentOn",
  },
];