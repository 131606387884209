import React, { useMemo, useState, useEffect, useRef } from "react";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import {
    Input,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Flex,
    Text,
    Image,
    Button,
    ButtonGroup,
    Box,
    SimpleGrid,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Divider,
    IconButton,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import Card from "components/card/Card";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useHideProfileAndIssueWarningMutation } from "data/slices/connect/connectSlice";
import { useRequestUserChangesMutation } from "data/slices/connect/connectSlice";
import StripeModalPopup from "components/sidebar/components/StripeModalPopup";

export default function ConnectTable({ columnsData, tableData, isPremiumAccount }) {
    const columns = useMemo(() => columnsData || [], [columnsData]);
    const data = useMemo(() => tableData || [], [tableData]);
    const [cellData, setCellData] = useState(data);
    const { isOpen: isOpenPricing, onOpen: onOpenPricing, onClose: onClosePricing } = useDisclosure()

    useEffect(() => {
        if (!columnsData || !tableData) {
            console.error("columnsData or tableData is undefined");
        }
    }, [columnsData, tableData]);

    const tableInstance = useTable(
        {
            columns,
            data: cellData,
            initialState: { pageSize: 9 }, // 9 rows per page to fit 3x3 grid
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex, pageSize },
        gotoPage,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        pageCount,
    } = tableInstance;

    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isHideProfileDialogOpen, setIsHideProfileDialogOpen] = useState(false);
    const [isRequestChangesDialogOpen, setIsRequestChangesDialogOpen] = useState(false);
    const [requestedChanges, setRequestedChanges] = useState(""); // State to store requested changes
    const inputRef = useRef(); // Ref for the input field
    const cancelRef = useRef();
    const [hideProfileApi, { isLoading: isHidingProfile }] = useHideProfileAndIssueWarningMutation();
    const [requestChangesApi, { isLoading: isRequestingChanges }] = useRequestUserChangesMutation();

    // Opening and closing the dialogs
    const openHideProfileDialog = () => setIsHideProfileDialogOpen(true);
    const closeHideProfileDialog = () => setIsHideProfileDialogOpen(false);
    const openRequestChangesDialog = () => setIsRequestChangesDialogOpen(true);
    const closeRequestChangesDialog = () => setIsRequestChangesDialogOpen(false);

    const handleViewClick = (item) => {
        if (!isPremiumAccount) {
            onOpenPricing();
        } else {
            setSelectedItem(item);
            setIsModalOpen(true);
        }
    };

    const hideProfile = (profileId) => {
        setCellData(prevData => prevData.filter(profile => profile.email !== profileId));
        // Assuming each profile has a unique identifier like 'id'
        hideProfileApi(profileId);
    };


    const requestChanges = (profileId) => {
        requestChangesApi(profileId, requestedChanges);
    };

    return (
        <Card direction="column" w="100%" px="0px" borderRadius="30px" overflowX={{ sm: "scroll", lg: "hidden" }}>
            <StripeModalPopup
                isOpen={isOpenPricing}
                onOpen={onOpenPricing}
                onClose={onClosePricing}
            />
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={5} marginRight={5} marginLeft={5}>
                {page.map((row) => {
                    prepareRow(row);
                    return (
                        <Box key={row.id} borderWidth="1px" borderRadius="lg" overflow="hidden" position="relative">
                            {row.original.profileImgs && row.original.profileImgs.length > 0 ? (
                                <Carousel showThumbs={false} showStatus={false} infiniteLoop>
                                    {row.original.profileImgs.map((image, index) => (
                                        <Image key={index} src={image} w="100%" h="200px" objectFit="cover" />
                                    ))}
                                </Carousel>
                            ) : (
                                <Box w="100%" h="200px" bg="gray.200" display="flex" alignItems="center" justifyContent="center">
                                    <Text>No Image Available</Text>
                                </Box>
                            )}
                            <Box textAlign="center" alignItems="center" p="6">

                                <Text
                                    mt="1"
                                    fontWeight="semibold"
                                    as="h4"
                                    textAlign="center"
                                    lineHeight="tight"
                                >
                                    {row.original.name} {row.original.pronouns && `(${row.original.pronouns})`}
                                </Text>

                                <Text
                                    color="gray.500"
                                    fontWeight="semibold"
                                    letterSpacing="wide"
                                    fontSize="xs"
                                    textTransform="uppercase"
                                >
                                    {row.original.graduationYear}
                                </Text>
                                <Box alignItems="center" mt="5" position="relative" textAlign="center">
                                    <Button
                                        alignItems="center"
                                        colorScheme="teal"

                                        size="sm"
                                        onClick={() => handleViewClick(row.original)}
                                    >
                                        manage
                                    </Button>
                                </Box>

                            </Box>

                        </Box>
                    );
                })}
            </SimpleGrid>

            <Flex justifyContent="center" mt="2">
                <ButtonGroup size="sm" isAttached variant="outline">
                    <IconButton
                        icon={<ChevronLeftIcon />}
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                        aria-label="Previous Page"
                    />
                    {Array.from({ length: pageCount }, (_, index) => (
                        <Button
                            key={index}
                            variant="outline"
                            colorScheme={pageIndex === index ? "teal" : undefined}
                            onClick={() => gotoPage(index)}
                        >
                            {index + 1}
                        </Button>
                    ))}
                    <IconButton
                        icon={<ChevronRightIcon />}
                        onClick={nextPage}
                        disabled={!canNextPage}
                        aria-label="Next Page"
                    />
                </ButtonGroup>
            </Flex>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Flex justify="center" align="center">
                            {/* <Text fontSize="lg" fontWeight="bold">
                                {selectedItem?.name}
                            </Text> */}
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedItem && (
                            <>
                                {selectedItem.profileImgs && selectedItem.profileImgs.length > 0 ? (
                                    <Carousel showThumbs={false} showStatus={false} infiniteLoop>
                                        {selectedItem.profileImgs.map((image, index) => (
                                            <Image key={index} src={image} w="100%" h="400px" objectFit="cover" />
                                        ))}
                                    </Carousel>
                                ) : (
                                    <Box w="100%" h="400px" bg="gray.200" display="flex" alignItems="center" justifyContent="center">
                                        <Text>No Image Available</Text>
                                    </Box>
                                )}
                                <Box mb={4}>
                                    {/* Basic Information */}
                                    <Text fontSize="lg" color="gray.800" fontWeight="bold" textAlign="center" mb={2} mt={2}>Basic Details</Text>
                                    <Text fontSize="md" color="gray.600" mb={2}>
                                        <b>Name:</b> {selectedItem.name}
                                    </Text>
                                    <Text fontSize="md" color="gray.600" mb={2}>
                                        <b>Email:</b> {selectedItem.email}
                                    </Text>
                                    {selectedItem.pronouns &&
                                        <Text fontSize="md" color="gray.600" mb={2}>
                                            <b>Pronouns:</b> {selectedItem.pronouns}
                                        </Text>}
                                    <Text fontSize="md" color="gray.600" mb={2}>
                                        <b>Graduation Year:</b> {selectedItem.graduationYear}
                                    </Text>
                                    {selectedItem.major &&
                                        <Text fontSize="md" color="gray.600" mb={2}>
                                            <b>Major:</b> {selectedItem.major}
                                        </Text>}
                                    {selectedItem.gender &&
                                        <Text fontSize="md" color="gray.600" mb={2}>
                                            <b>Gender:</b> {selectedItem.gender}
                                        </Text>}
                                    {selectedItem.languages && selectedItem.languages.length > 0 && (
                                        <Text fontSize="md" color="gray.600" mb={2}>
                                            <b>Languages:</b> {selectedItem.languages.join(", ")}
                                        </Text>
                                    )}

                                    {/* Social Information */}
                                    <Divider mb={4} />

                                    <Text fontSize="lg" color="gray.800" fontWeight="bold" textAlign="center" mb={2}>Social Information</Text>
                                    {selectedItem.bio && (
                                        <>
                                            <Text fontSize="md" color="gray.600" mb={2}>
                                                <b>Biography: </b>{selectedItem.bio}
                                            </Text>
                                        </>
                                    )}
                                    {selectedItem.instagram && (
                                        <Text fontSize="md" color="gray.600" mb={2}>
                                            <b>Instagram: </b> {selectedItem.instagram}
                                        </Text>
                                    )}
                                    {selectedItem.snapchat && (
                                        <Text fontSize="md" color="gray.600" mb={2}>
                                            <b>Snapchat: @</b>{selectedItem.snapchat}
                                        </Text>
                                    )}
                                    {selectedItem.socialPreferences && selectedItem.socialPreferences.length > 0 && (
                                        <>
                                            <Text fontSize="md" color="gray.600" mb={2}>
                                                <b>Social Preferences:</b> {selectedItem.socialPreferences.join(", ")}
                                            </Text>
                                        </>
                                    )}
                                    {selectedItem.socialInterests && selectedItem.socialInterests.length > 0 && (
                                        <Text fontSize="md" color="gray.600" mb={2}>
                                            <b>Social Interests:</b> {selectedItem.socialInterests.join(", ")}
                                        </Text>
                                    )}
                                    {selectedItem.zodiac && (
                                        <Text fontSize="md" color="gray.600" mb={2}>
                                            <b>Zodiac Sign:</b> {selectedItem.zodiac}
                                        </Text>
                                    )}
                                    <Divider mb={4} />

                                    {/* Geolocation Details */}
                                    <Text fontSize="lg" color="gray.800" fontWeight="bold" textAlign="center" mb={2}>Geolocation Details</Text>

                                    {selectedItem.hometown && (
                                        <Text fontSize="md" color="gray.600" mb={2}>
                                            <b>Hometown:</b> {selectedItem.hometown}
                                        </Text>
                                    )}
                                    {selectedItem.livingLocation && (
                                        <Text fontSize="md" color="gray.600" mb={2}>
                                            <b>Lives in:</b> {selectedItem.livingLocation}
                                        </Text>
                                    )}
                                    {selectedItem.currentLocation && (
                                        <Text fontSize="md" color="gray.600" mb={2}>
                                            <b>Current Location:</b> {selectedItem.currentLocation}
                                        </Text>
                                    )}

                                </Box>
                            </>
                        )}
                    </ModalBody>





                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={openHideProfileDialog}>
                            Hide Profile
                        </Button>
                        <Button colorScheme="yellow" onClick={openRequestChangesDialog}>
                            Request Changes
                        </Button>
                        <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
                            Close
                        </Button>

                        {/* Hide Profile Confirmation Dialog */}
                        <AlertDialog
                            isOpen={isHideProfileDialogOpen}
                            leastDestructiveRef={cancelRef}
                            onClose={closeHideProfileDialog}
                        >
                            <AlertDialogOverlay>
                                <AlertDialogContent>
                                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                        Hide Profile
                                    </AlertDialogHeader>
                                    <AlertDialogBody>
                                        Are you sure you want to hide this profile?
                                    </AlertDialogBody>
                                    <AlertDialogFooter>
                                        <Button ref={cancelRef} onClick={closeHideProfileDialog}>
                                            Cancel
                                        </Button>
                                        <Button colorScheme="red" onClick={() => {
                                            // Perform action to hide profile
                                            hideProfile(selectedItem.email); // Assuming there's a function to remove the profile from your data
                                            closeHideProfileDialog(); // Close the dialog
                                            setIsModalOpen(false); // Close the profile modal
                                        }} ml={3}>
                                            Hide
                                        </Button>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialogOverlay>
                        </AlertDialog>



                        {/* Request Changes Confirmation Dialog */}
                        <AlertDialog
                            isOpen={isRequestChangesDialogOpen}
                            leastDestructiveRef={cancelRef}
                            onClose={closeRequestChangesDialog}
                        >
                            <AlertDialogOverlay>
                                <AlertDialogContent>
                                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                        Request Changes
                                    </AlertDialogHeader>
                                    <AlertDialogBody>
                                        <Input ref={inputRef} placeholder="Type your requested changes..." value={requestedChanges} onChange={(e) => setRequestedChanges(e.target.value)} />
                                    </AlertDialogBody>
                                    <AlertDialogFooter>
                                        <Button ref={cancelRef} onClick={closeRequestChangesDialog}>
                                            Cancel
                                        </Button>
                                        <Button colorScheme="yellow" onClick={() => {
                                            requestChanges();
                                            closeRequestChangesDialog();
                                        }} ml={3}>
                                            Request
                                        </Button>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialogOverlay>
                        </AlertDialog>
                    </ModalFooter>


                </ModalContent>
            </Modal>
        </Card>
    );
}
