import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";

const connectProfilesAdapter = createEntityAdapter();

const initialState = connectProfilesAdapter.getInitialState();

export const announcementApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAnnouncements: builder.query({
            query: (school) => ({
                url: '/api/push/announcements',
                method: 'GET',
                params: { school },
            }),
            providesTags: ['Announcements'],
            invalidatesTags: ['Announcements'],
        }),
        sendAnnouncement: builder.mutation({
            query: (notification) => ({
                url: '/api/push/announcement',
                method: 'POST',
                body: notification,
            }),
            // Invalidate tags after a successful mutation
            invalidatesTags: ['Announcements'],
            // Define how to modify the cache after a successful mutation
            onSuccess: (data, variables, api) => {
                // Invalidate the 'Announcements' tag
                api.invalidateTags(['Announcements']);
            },
            // Optionally, handle errors
            onError: (error, variables, api) => {
                // Handle errors here if needed
            },
        }),
    }),
});

export const { useGetAnnouncementsQuery, useSendAnnouncementMutation } = announcementApiSlice;
