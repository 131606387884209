// Chakra imports
// Custom components
import {
    Autocomplete,
    Spinner,
    Stack,
    Box,
    Button,
    Checkbox,
    Tag, TagLabel, TagCloseButton,
    CheckboxGroup,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
    FormErrorMessage,
    Select,
    InputLeftElement,
    Textarea,
    Avatar,
    Alert,
} from "@chakra-ui/react";
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import { Formik, Form, Field } from "formik";
import Card from "components/card/Card.js";
import React, { useState } from "react";
// import { Select } from "chakra-react-select";
import { PhoneIcon, AddIcon, WarningIcon, CheckIcon, LinkIcon, EmailIcon, EditIcon, Search2Icon } from '@chakra-ui/icons'
// Assets
import { MdUpload } from "react-icons/md";
import { FaBath, FaBed, FaShippingFast } from "react-icons/fa";
import { useGetSchoolsDataQuery } from "../../../../data/slices/listings/listingsslice";
import { generateDropDownSchools } from "../../../../util/util";

import Dropzone from "views/admin/listing/components/Dropzone";
import { DatePicker } from 'chakra-ui-date-input'
import goldenStar from "assets/img/auth/golden_star.png";

export default function MarketplaceForm(props) {
    const { handleSubmit, isPlanEligibleToPostToAllSchools, subcategories, maxSchoolsListingLimit, userData } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const brandColor = useColorModeValue("brand.500", "white");
    const textColorSecondary = "gray.400";
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const [fixed, setFixed] = React.useState(false);
    const [schoolInputValue, setSchoolInputValue] = useState('');
    const { data, isLoading, isSuccess, isError, error } = useGetSchoolsDataQuery(schoolInputValue);
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [schoolErrorMsg, setSchoolErrorMsg] = React.useState(null);

    React.useEffect(() => {
        const marketplaceDraft = JSON.parse(localStorage.getItem('marketplaceDraft'));

        if (marketplaceDraft && marketplaceDraft.schools) {
            const existingSelection = marketplaceDraft.schools.map((value) => ({
                school: value,
                label: value,
                value: value,
                logoUrl: goldenStar // Assuming "goldenStar" is a string value
            }));
            setSelectedItems(existingSelection);
        }

    }, []);


    const handleSelectedItemsChange = (newSelectedItems, setFieldValue) => {
        if (newSelectedItems && newSelectedItems.length <= maxSchoolsListingLimit) {
            const hadAllValue = selectedItems ? selectedItems.some(item => item.school === "All") : false;
            const hasAllValue = newSelectedItems.some(item => item.school === "All");
            var newList = hasAllValue && !hadAllValue && isPlanEligibleToPostToAllSchools ? [{ school: "All", label: "All", value: "All", logoUrl: goldenStar }] : newSelectedItems.filter(item => item.school !== "All" || item.label !== "All");
            setSelectedItems(newList);
            setFieldValue('schools', newList ? newList.map(obj => obj.school ? obj.school : obj.label) : null);
            setSchoolErrorMsg(null);
        } else {
            setSchoolErrorMsg("You have reached the maximum number of schools allowed on your current plan.");
        }
    };



    const handleSchoolInputChange = async (event) => {
        if (event.target.value) {
            setSchoolInputValue(event.target.value);
            // setItems(schoolsData);
        } else {
            setSchoolInputValue('');
            // setItems(schoolsData);

        }
    };



    const customRender = (selected) => {
        return (
            <Flex flexDir="row" alignItems="center">

                {selected.logoUrl ? <Avatar mr={2} size="sm" src={selected.logoUrl} /> : <Avatar mr={2} size="sm" name={selected.school} />}
                <Text>{selected.school}</Text>
            </Flex>
        )
    }

    const getInitialValues = () => {
        const marketplaceDraft = localStorage.getItem('marketplaceDraft');

        try {
            if (marketplaceDraft) {
                const parsedDraft = JSON.parse(marketplaceDraft);
                parsedDraft.type = "Marketplace";
                return parsedDraft;
            }
        } catch (error) {
            console.error('Error parsing marketplaceDraft:', error);
        }

        return {
            price: null,
            title: null,
            type: "Marketplace",
            // schools: [userData?.school],
            email: null,
            phone: null,
            shippingCost: null,
            subcategory: null,
            usefulLink: null
        };
    };

    const handleFormChange = async (values) => {
        await localStorage.setItem('marketplaceDraft', JSON.stringify(values));
    };

    return (
        <Card
            borderTopRightRadius='20px'
            borderBottomRightRadius='20px'
            direction='column'
            px='15px'
            overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Formik
                enableReinitialize={true}
                initialValues={getInitialValues()}
                onSubmit={async (values, { setSubmitting, setErrors, setStatus, resetForm, setValues }) => {
                    /**
                     ** this is a temporary fix
                     * for some reason props.setFieldValue does not set values if thhe array is one element?
                     * * 
                     * */
                    // if (selectedItems && (!values.schools || values.schools.length == 0)) {
                    //     values.schools = selectedItems.map(obj => obj.school ? obj.school : obj.label);
                    // }
                    try {
                        // if (!values.schools || values.schools.length == 0) {
                        //     setSchoolErrorMsg('You must select at least one school');
                        //     return;
                        // } else {
                        //     await handleSubmit(values, setSelectedItems);
                        //     setSchoolErrorMsg(null);
                        // }
                        await handleSubmit(values, setSelectedItems);
                        setSchoolErrorMsg(null);
                    } catch (error) {
                        setStatus({ success: false })
                        setSubmitting(false)
                        setErrors({ submit: error.message })
                    }


                }}
            >
                {(props) => (
                    <Form style={{ overflow: 'hidden' }} onChange={async () => await handleFormChange(props.values)}>
                        <Stack direction="column">
                            <Field name="title" >
                                {({ field, form }) => (
                                    <FormControl
                                    >
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'>
                                            Name of the listing<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size='md'>
                                            <Input
                                                {...field} id="title"
                                                isRequired={true}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                placeholder='Enter title for your post'
                                                mb='12px' // | space
                                                width='95%'
                                                fontWeight='500'
                                                size='lg'
                                                isDisabled={props.isSubmitting}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                )}

                            </Field>
                        </Stack>
                        <Stack direction="row" mt="20px">
                            <Field name="subcategory">
                                {({ field }) => (
                                    <FormControl>
                                        <Select
                                            {...field}
                                            id="subcategory"
                                            placeholder="Subcategory"
                                            variant="auth"
                                            fontSize="sm"
                                            isRequired={true}
                                            ms={{ base: "0px", md: "0px" }}
                                            mb="12px" // | space
                                            width="95%"
                                            fontWeight="500"
                                            size="lg"
                                        >
                                            {subcategories.map((subcategory) => (
                                                <option key={subcategory} value={subcategory}>
                                                    {subcategory}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Field>
                        </Stack>
                        <Stack direction="row" mt="20px">
                            <Field name="email">
                                {({ field, form }) => (
                                    <FormControl>
                                        <FormLabel
                                            ms="4px"
                                            fontSize="sm"
                                            fontWeight="100"
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display="flex"
                                        >
                                            Email<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size="md">
                                            <InputLeftElement
                                                pointerEvents="none"
                                                children={<EmailIcon color="gray.300" mt="5px" />}
                                            />
                                            <Input
                                                {...field}
                                                id="email"
                                                isRequired={true}
                                                variant="auth"
                                                fontSize="sm"
                                                ms={{ base: "0px", md: "0px" }}
                                                type="email"
                                                placeholder="mail@collegesouk.com"
                                                mb="24px"
                                                fontWeight="500"
                                                size="lg"
                                                w="90%"
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="phone" >
                                {/* Trouble in this line below*/}
                                {({ field, form }) => (
                                    <FormControl
                                    >
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={false}
                                            display='flex'>
                                            Phone Number<Text color={brandStars}></Text>
                                        </FormLabel>
                                        <InputGroup size='md'>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                children={<PhoneIcon color='gray.300' mt='5px' />}
                                            />
                                            <Input
                                                {...field}
                                                id="phone"
                                                isRequired={false}
                                                fontSize='sm'
                                                placeholder='555-556-5488'
                                                mb='24px'
                                                maxLength={11}
                                                size='lg'
                                                type='tel'
                                                variant='auth'
                                                w='90%'
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.phone}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Stack>
                        {/* {schoolErrorMsg ?
                            <Flex flexDirection={"column"} align='center' mb='10px'>
                                <Alert status='error'>
                                    {schoolErrorMsg}
                                </Alert>
                            </Flex> : null}
                        <Stack direction='row'>

                            <Field name="schools" >
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.schools && form.touched.schools}>

                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'
                                            width='10rem'
                                        >
                                            School(s)<Text color={brandStars}>*</Text>
                                        </FormLabel>

                                        <CUIAutoComplete
                                            // disableCreateItem={true}

                                            inputStyleProps={{
                                                variant: 'auth',
                                                size: 'lg',
                                                w: '95%',
                                                fontSize: 'sm',
                                                fontWeight: '500',
                                                pointerEvents: (props.isSubmitting || (selectedItems && selectedItems.length > maxSchoolsListingLimit)) ? "none" : "auto"
                                            }}
                                            disableCreateItem={true}
                                            renderCustomInput={(inputProps) => (
                                                <InputGroup size="md">
                                                    <InputLeftElement
                                                        pointerEvents='none'
                                                        children={<Search2Icon color='gray.300' mt='5px' />}
                                                    />
                                                    <Input
                                                        {...field}
                                                        {...inputProps}
                                                        id="schools"
                                                        isRequired={selectedItems && selectedItems.length > 0 ? false : true}
                                                        onInput={handleSchoolInputChange}
                                                        placeholder='Search School(s)'
                                                    />
                                                </InputGroup>
                                            )}
                                            placeholder="Search for School"
                                            hideToggleButton={false}
                                            size="sm"
                                            items={generateDropDownSchools(isLoading, isPlanEligibleToPostToAllSchools, data)}
                                            itemRenderer={customRender}
                                            selectedItems={selectedItems}
                                            onSelectedItemsChange={async (changes) => {
                                                await handleSelectedItemsChange(changes.selectedItems, props.setFieldValue)
                                            }
                                            }

                                        />
                                        <FormErrorMessage>
                                            {form.errors.schools}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                        </Stack> */}
                        <Stack direction='row'>
                            <Field name="price" >
                                {/* Trouble in this line below*/}
                                {({ field, form }) => (
                                    <FormControl

                                    >
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={true}
                                            display='flex'>
                                            Price<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size='md'>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                color='gray.300'
                                                fontSize='1.2em'
                                                children='$'
                                                mt='3px'
                                            />
                                            <Input
                                                {...field} id="price"
                                                isRequired={true}
                                                fontSize='sm'
                                                placeholder='Enter amount'
                                                mb='24px'
                                                size='lg'
                                                type={"number"}
                                                variant='auth'
                                                w='90%'
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.price}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="usefulLink" >

                                {({ field, form }) => (
                                    <FormControl
                                    >
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='100'
                                            color={textColorPrimary}
                                            isRequired={false}
                                            display='flex'>
                                            Action Button Link<Text color={brandStars}></Text>
                                        </FormLabel>
                                        <InputGroup size='md'>

                                            <InputLeftElement
                                                pointerEvents='none'
                                                children={<LinkIcon color='gray.300' />}
                                            />
                                            <Input
                                                w='90%'
                                                {...field} id="usefulLink"
                                                isRequired={false}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                placeholder='Enter Link'
                                                mb='12px' // | space
                                                fontWeight='500'
                                                size='lg'
                                                type="link"
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.usefulLink}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Stack>

                        <Stack direction='column'>
                            <Stack spacing='10%' direction='row'>
                                <FormLabel mt='6px'
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='100'
                                    color={textColorPrimary}
                                    display='flex'>
                                    Shipping Cost<Text color={brandStars}></Text>
                                </FormLabel>
                                <Checkbox
                                    size='sm'
                                    value='fixed'
                                    isChecked={fixed}
                                    onChange={() => setFixed(!fixed)}
                                >
                                    Fixed
                                </Checkbox>
                                <Checkbox
                                    size='sm'
                                    value='calculated'
                                    isChecked={!fixed}
                                    onChange={() => {
                                        props.setFieldValue('shippingCost', null)
                                        setFixed(false)
                                    }
                                    }
                                >
                                    None
                                </Checkbox>
                            </Stack>

                            {fixed ?
                                <Field name="shippingCost" >
                                    {/* Trouble in this line below*/}
                                    {({ field, form }) => (
                                        <FormControl
                                        >
                                            <InputGroup size='md'>
                                                <InputLeftElement
                                                    pointerEvents='none'
                                                    color='gray.300'
                                                    fontSize='1.2em'
                                                    mt='3px'
                                                >
                                                    <Box mr="4px" mt="4px">
                                                        <Icon as={FaShippingFast} />
                                                    </Box>

                                                </InputLeftElement>

                                                <Input
                                                    {...field} id="shippingCost"
                                                    isRequired={fixed ? true : false}
                                                    fontSize='sm'
                                                    placeholder='Enter amount'
                                                    mb='24px'
                                                    size='lg'
                                                    type={"number"}
                                                    variant='auth'
                                                    w='95%'
                                                />
                                            </InputGroup>
                                            <FormErrorMessage>
                                                {form.errors.shippingCost}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field> : null}
                        </Stack>
                        <Flex w='95%' mb='60px'>
                            <Button
                                fontSize='lg'
                                fontWeight='semibold'
                                variant='brand'
                                color='black'
                                w='220px'
                                h='50px'
                                mt='2.5rem'
                                mb='2.5rem'
                                isLoading={props.isSubmitting}
                                type="submit"
                                background='#A3F579'
                                ms='34%'
                                boxShadow='0px 4px 4px rgba(0, 0, 0, 0.15)'
                                borderRadius='50px'
                                _hover={{ background: '#95dd6e' }}>
                                Create Listing
                            </Button>
                        </Flex>

                    </Form>

                )}
            </Formik>
        </Card>
    );
}

