/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Menu,
    MenuList,
    MenuButton,
    MenuItem,
    Spinner,
    FormErrorMessage,
    InputGroup,
    Textarea
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";

import { Box, Flex, Button, Text, SimpleGrid, useDisclosure } from "@chakra-ui/react";
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Stack } from "@chakra-ui/react";
import { useCreateTaskMutation, useGetUserQuery, useGetTasksQuery } from "data/slices/users/usersSlice";


// Custom components



import React, { useState, useEffect } from 'react';


import MarketplaceTable from "views/admin/dataTables/components/MarketplaceTable";
import {
    columnsDataCheck,
} from "views/admin/marketplace/variables/columnsData.js";

import { buildTasksTable, buildThreadsTable, isPremium } from '../../../util/util'
import { useGetSchoolDetailsQuery } from "data/slices/school/schoolSlice";
import { useGetMarketplaceStudentListingsQuery } from "data/slices/listings/listingsslice";
import { useGetSubDetailsQuery } from "data/slices/subscription/subscription";
export default function Tasks() {
    const [createTask, { isLoading: isCreatingTask, error: error, data: data }] = useCreateTaskMutation();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
    const [selectedReminder, setSelectedReminder] = useState("Select");
    const handleReminderSelect = (reminder) => { setSelectedReminder(reminder); };
    const [selectedPriority, setSelectedPriority] = useState('');
    const { data: userData, isLoading: isLoadingUser, isSuccess: isSuccessUser, isError: isErrorUser, error: userError } = useGetUserQuery();
    const { data: tasks, isLoading: isLoadingTasks, isSuccess: isSuccessTasks, isError: isErrorTasks, error: tasksError, refresh } = useGetMarketplaceStudentListingsQuery(userData?.school);
    const { data: schoolDetails, isLoading: isLoadingSchool, isSuccess: isSuccessLoading, isError: isErrorSchool, error: schoolError } = useGetSchoolDetailsQuery(userData?.school);
    const { data: subDetails, error: subError, isLoading: isLoadingSub } = useGetSubDetailsQuery(userData?.email);
  
    const isPremiumAccount = isPremium(subDetails);
    const handleClose = () => {
        onClose();
        // Set the button's background color here
        // Replace "#A3F579" with your desired color
        document.getElementById("createTaskButton").style.backgroundColor = "#A3F579";
    };
    useEffect(() => {
        // Code to execute when 'tasks' value changes
        console.log('Marketplace Listings have changed:', tasks?.length);
    }, [tasks]);

    if (isLoadingUser || isLoadingTasks || isLoadingSchool || isLoadingSub) {
        return (<Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            height='100vh'
        >
            <Spinner size='xl' />
        </Box>)
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text
                    color={"black"}
                    fontSize="24"
                    fontWeight={"400"}
                    lineHeight='100%'>
                    {/* {userTasks} */}
                    {/* <ChevronDownIcon />
            My tasks */}
                </Text>
            </Flex>
            <SimpleGrid>
                <MarketplaceTable columnsData={columnsDataCheck} tableData={tasks} schoolData={schoolDetails} isPremiumAccount={isPremiumAccount} />
            </SimpleGrid>
        </Box>
    );
}

