import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";

const usersAdapter = createEntityAdapter()

const initialState = usersAdapter.getInitialState()

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUser: builder.query({
            query: () => `/api/user`,
            // transformResponse: responseData => {
            //     return usersAdapter.setAll(initialState, responseData)
            // },
            providesTags: ['user']
        }),
        getUserStats: builder.query({
            query: (school) => `/api/user/stats/${school}`,
            // transformResponse: responseData => {
            //     return usersAdapter.setAll(initialState, responseData)
            // },
            providesTags: ['userStats'],
            enabled: (id) => id != null
        }),
        verifyUserEmail: builder.mutation({
            query: (email) => ({
                url: `/api/user/email/verification/${email}`,
                method: 'POST',
            }),
        }),
        verifyUserPhone: builder.mutation({
            query: ({ email, phoneNumber }) => ({
                url: `/api/user/phone/verification/${email}/${phoneNumber}`,
                method: 'POST',
            }),
        }),
        markUserEmailVerified: builder.mutation({
            query: (email) => ({
                url: '/api/user/email/verified',
                method: 'POST',
                params: { email: email },
            }),
        }),
        markUserPhoneVerified: builder.mutation({
            query: ({ email, phoneNumber }) => ({
                url: '/api/user/phone/verified',
                method: 'POST',
                params: { email: email, phone: phoneNumber },
            }),
        }),
        createTask: builder.mutation({
            query: (body) => ({
                url: '/api/task',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['tasks']
        }),
        getTasks: builder.query({
            query: (email) => ({
                url: '/api/tasks',
                method: 'GET',
                params: { email: email },
            }),
            providesTags: ['tasks'],
            enabled: (email) => email != null
        }),
        getLeads: builder.query({
            query: (email) => ({
                url: `/api/leads/${email}`,
                method: 'GET'
            }),
            providesTags: ['leads'],
            enabled: (email) => email != null
        }),
        getCrmDetails: builder.query({
            query: (email) => ({
                url: `/crm/details`,
                method: 'GET',
                params: { email: email },
            }),
            providesTags: ['crmDetails'],
            enabled: (email) => email != null
        }), 
        updateCrmDetails: builder.mutation({
            query: (formData) => ({
                url: `/crm/update`,
                method: 'POST',
                body: formData
            }),
            invalidatesTags: ['crmDetails'],

        }),
    }),
})

export const {
    useGetUserQuery,
    useGetUserStatsQuery,
    useVerifyUserEmailMutation,
    useVerifyUserPhoneMutation,
    useMarkUserEmailVerifiedMutation,
    useMarkUserPhoneVerifiedMutation,
    useCreateTaskMutation,
    useGetTasksQuery,
    useGetLeadsQuery,
    useGetCrmDetailsQuery,
    useUpdateCrmDetailsMutation
} = usersApiSlice;


// Selector to get the user data from the store
export const selectUser = createSelector(
    (state) => {
        const getUserQuery = usersApiSlice.endpoints.getUser.select(state);
        return getUserQuery ? getUserQuery.data : undefined;
    },
    (data) => {
        console.log('Selected user data:', data);
        return data || {};
    }
);


// Selector to get the user stats data from the store
export const selectUserStats = createSelector(
    (state, userId) =>
        state.usersApiSlice.endpoints.getUserStats.select(userId),
    (data) => data?.data
);



