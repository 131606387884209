/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import Board from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import "assets/css/Plugins.css";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import {
  ModalFooter,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Box,
  Button,
  Flex,
  Icon,
  FormControl,
  IconButton,
  Image,
  Input,
  Text,
  useColorModeValue,
  useDisclosure,
  Stack,
  Grid
} from "@chakra-ui/react";
import {
  FaImage,
  FaTimes
} from "react-icons/fa";
import { MdCheckCircle, MdCancel, MdOutlineError, MdVisibility, MdOutlineTimer, MdDone, MdAttachMoney } from "react-icons/md";

import React, { useState, useRef, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import ellipseMark from "assets/img/applications/kanbanX.png"
import { GoGear } from "react-icons/go";
import { typeOf } from "react-is";
import { useGetListingsQuery, useDeleteListingMutation, useChangeStatusMutation } from "../../../data/slices/listings/listingsslice";
import { useGetUserQuery } from "../../../data/slices/users/usersSlice";
import { useGetSubDetailsQuery } from "../../../data/slices/subscription/subscription";
import { convertListingsToCards, isPremium, isPlanEligibleToPostToAllSchools, removeCardById } from "../../../util/util"
import EditModal from "./EditModal"
import { useHistory } from "react-router-dom";
import StripeModalPopup from "../../../components/sidebar/components/StripeModalPopup"

function Kanban() {
  const { isOpen, onOpen, onClose } = useDisclosure();


  // Chakra color mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { data, isLoading: isLoadingUser, isSuccess: isSuccessUser, isError: isErrorUser, error: userError } = useGetUserQuery();
  const {
    data: listingData,
    isLoading: isLoadingListings,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetListingsQuery(data?.email);
  const history = useHistory();

  const [listings, setListings] = useState(listingData ? listingData : []);

  const [board, setBoard] = useState(convertListingsToCards(listingData));
  const { data: subDetails, error: isErrorSub, isLoading: isLoadingSub } = useGetSubDetailsQuery(data?.email);
  const [deleteListing, { isLoading: isDeleting }] = useDeleteListingMutation();
  const [changeStatus, { isLoading: isChangingStatus }] = useChangeStatusMutation();
  const [errorMessage, setErrorMessage] = useState(null);

  const isPremiumAccount = isPremium(subDetails);

  const isPlanEligibleToPostToAll = isPlanEligibleToPostToAllSchools(subDetails);

  const [currentListingId, setCurrentListingId] = useState(null);
  const [removeCurrentCard, setRemoveCurrentCard] = useState(null);
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandText = useColorModeValue("brand.500", "white");
  const { isOpen: isOpenPremium, onOpen: onOpenPremium, onClose: onClosePremium } = useDisclosure()
  const { isOpen: isOpenErrorPopup, onOpen: onOpenErrorPopup, onClose: onCloseErrorPopup } = useDisclosure()

  useEffect(() => {
    setListings(listingData)
    setBoard(convertListingsToCards(listings));
  }, [listingData]);

  useEffect(() => {
    console.log(board);
  }, [board, listings]);

  useEffect(() => {
    console.log("is Loading Listings ", isLoadingListings);
    console.log("is Loading User ", isLoadingUser);
    console.log("board length ", board)
    console.log("userData ", data)
    console.log("listingsData ", listingData)
  }, [isLoadingUser, isLoadingListings, board]);

  if (isLoadingUser || isLoadingListings || !data || !listingData) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        height='100vh'
      >
        <Spinner size='xl' />
      </Box>
    )
  }

  if (isError || isErrorUser) {
    return <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      height='100vh'
    >      <Text
      color={textColor}
      fontSize='34px'
      fontWeight='700'
      lineHeight='100%'>Error: {error.status}</Text>
    </Box>
  }

  function onCardNew(newCard) {
    // const newCardLocal = { id: initialBoard.counter + 1, ...newCard };
    // initialBoard.counter = initialBoard.counter + 1;
    // setBoard(initialBoard);
    // return newCardLocal;
    console.log("this is the new card");
  }

  function getBoxColor(title) {
    if (title === "Inactive") {
      return "#044AFF"
    }
    // else if (title === "Pending Review") {
    //   return "#FFB800"
    // }
    else if (title === "Active") {
      return "#00FF19"
    }
    else if (title === "Sold" || title == "Archived") {
      return "#FF00E5"
    }
  }

  const throwError = (msg) => {
    setErrorMessage(msg)
    onOpenErrorPopup();
    throw new Error("Invalid Operation!");
  };


  /**
   *  logic is there are 4 columns Inactive, Pending Review, Active, Sold
   *  each card can only be dragged to the previous column
   * 
   */

  const handleCardDragEnd = (newBoard, listing, position, destination) => {
    if (!isPremiumAccount) {
      onOpenPremium();
      return;
    }
    const fromColumnId = position.fromColumnId;
    const toColumnId = destination.toColumnId;

    const listingId = listing?._id;

    switch (fromColumnId) {
      case 1: // New
        if (toColumnId == 2) { // New to Active
          handleChangeStatus(listingId, "ACTIVE");
        } else {
          throwError("Please submit your listing for activation.");
        }
        break;
      case 2: // Active
        if (toColumnId == 3) { // Active to Sold
          handleChangeStatus(listingId, "SOLD");
        } else {
          throwError("The current status of this listing is active.\n\nYou have the option to either mark it as 'Archived' or delete it.");
        }
        return; // Cards in Sold column cannot be moved
      case 3: // Sold
        throwError("Archived items are not eligible for re-activation.\n\nPlease make a new listing.");
        break;
      default:
        throwError("Invalid column ID.");
    }
  };



  const handleModalClose = () => {
    setCurrentListingId(null);
    onClose();
  };

  const handleChangeStatus = async (id, newStatus) => { // method that changes the status of a listing on the backend
    if (isPremiumAccount) {
      try {
        await changeStatus({ id: id, newStatus: newStatus }).unwrap();
      } catch (err) {
        switch (err?.status) {
          case undefined:
            break;
          case 403:
            setErrorMessage("Changing the status from 'Pending Review' to 'Active' is not allowed. Your listings are automatically moved to the 'Active' status upon approval by our review team. <br/><br/>Please note that the review process might take up to 48 hours to ensure compliance with our guidelines and policies.");
            onOpenErrorPopup();
            break;
          default:
            console.log("error changing status", err);
        }
      }
    }
  };

  const handleDeleteListing = async (id) => {
    try {
      removeCurrentCard();
      await deleteListing(id).unwrap();
      onClose();
      // Get updated listings data after delete
      setBoard(removeCardById(board, id));
      var temp = listings;
      var updatedListings = temp.filter(l => l !== null && l._id !== id);
      setListings(updatedListings);
      setCurrentListingId(null);
      setRemoveCurrentCard(null);
    } catch (err) {
      console.log(err)
    }
  };


  return (
    <Flex>
      <StripeModalPopup onOpen={onOpenPremium} onClose={onClosePremium} isOpen={isOpenPremium} />
      <Modal isOpen={isOpenErrorPopup} onClose={onCloseErrorPopup}>
        <ModalOverlay />
        <ModalContent borderRadius="20px" width={{ base: "90%", sm: "80%", md: "60%" }}>
          <ModalBody>
            <Box align="center" mb="20px">
              <ModalHeader>Invalid Operation</ModalHeader>
            </Box>
            <Box align="center" display="flex" justifyContent="center" mb="20px">
              <Icon w="54px" h="54px" me="5px" color="red.500" as={FaTimes} />
            </Box>
            <Box align="center" mb="20px" mr="30px">
              <ModalFooter>
                {errorMessage}
              </ModalFooter>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>


      {currentListingId && <EditModal isOpen={isOpen} onOpen={onOpen} onClose={handleModalClose} listingId={currentListingId} handleDeleteListing={handleDeleteListing} isDeleting={isDeleting} isPlanEligibleToPostToAllSchools={isPlanEligibleToPostToAll} />}
      <Flex mt='80px' borderRadius='15px'
        direction='column'>
        <Flex px='25px' justify='space-between' mb='20px' align='center' mt='10px'>
          <Text
            color={textColor}
            fontSize='22px'
            fontWeight='700'
            lineHeight='100%'
            mt='20px'>
          </Text>


        </Flex>

        <Flex maxWidth='100%'>
          {/* <Scrollbars
            autoHide
            renderTrackHorizontal={kanbanRenderTrack}
            renderThumbHorizontal={kanbanRenderThumb}
            renderView={kanbanRenderView}> */}
          <Board
            allowRemoveCard={true}
            initialBoard={convertListingsToCards(listingData)}
            disableColumnDrag={true}
            onCardRemove={handleDeleteListing}

            onCardDragEnd={handleCardDragEnd}
            allowAddCard={{ on: "top" }}
            onNewCardConfirm={onCardNew}
            allowRemoveLane={false}
            renderColumnHeader={function ({ title, id }, { addCard }) {
              return (
                <Flex
                  key={id}
                  flexDirection='column'
                  // mb='7px'
                  fontWeight='bold'
                  w='100%'>

                  <Flex align='center' mb='24px' backgroundColor='#F4F4F4'
                    mt='10px'
                    height='40px'
                    borderTopRightRadius={'35px'}
                    borderBottomRightRadius={'35px'}
                    w='auto'
                  >
                    <Flex w='10px'></Flex>
                    <Box mr='15px' p='1' bg={getBoxColor(title)} height='30px' />
                    <Text fontSize='15px' color={textColor} >
                      {title}
                    </Text>
                  </Flex>
                  {title === "Inactive" ? (
                    <IconButton
                      w='100%'
                      h='35px'
                      borderRadius='12px'
                      aria-label='Add Listing'
                      variant='no-hover'
                      bg={bgButton}
                      align='center'
                      icon={<AddIcon w='12px' h='12px' color={brandText} />}
                      onClick={() => history.push("/admin/create")}
                    >
                      Create Listing
                    </IconButton>
                  ) : null}
                </Flex>
              );
            }}
            renderCard={(itemDetails, { removeCard, dragging }) => {
              const { image, title, category, submissionDate, status, address, id } = itemDetails;
              return (
                <Flex
                  key={id}
                  backgroundColor='#F4F4F4'
                  mt='5px'
                  flexDirection='column'
                  p='5px'
                  pr='20px' // add this line
                  borderRadius='9px'
                  w='350px'
                  position='relative'
                >
                  <Box
                    position='absolute'
                    top='0'
                    right='0' // modify this line from 'right' to 'left'
                    w="28px"
                    h="28px"
                    borderRadius="full"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    _hover={{ bg: 'gray.300' }}
                    onClick={async () => {
                      if (!isPremiumAccount) {
                        onOpenPremium();
                      } else {
                        console.log(id);
                        await setRemoveCurrentCard(() => removeCard)
                        await setCurrentListingId(id);
                        onOpen();
                      }
                    }}
                  >
                    <EditIcon mt="8px" color="gray.500" />
                  </Box>
                  <Flex justifyContent="center" alignItems="center" position="relative">
                    <Text
                      fontSize='xs'
                      color="black"
                      noOfLines={1}
                      isTruncated
                      width="100%"
                      px={2}
                      py={1}
                      style={{ fontStyle: 'italic', fontSize: 'sm' }}
                    >
                      {category}
                    </Text>

                  </Flex>
                  <Flex justifyContent="center" alignItems="center" position="relative">
                    {image ? (
                      <Image
                        w='20px'
                        h='20px'
                        borderRadius={"20px"}
                        objectFit={"cover"}
                        src={image}
                        mr={2}
                      />
                    ) : (
                      <Box
                        w='20px'
                        h='20px'
                        borderRadius={"20px"}
                        backgroundColor="#F4F4F4"
                        mr={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Icon as={FaImage} color="green.500" />
                      </Box>
                    )}
                    <Text
                      fontSize='sm'
                      color="black"
                      noOfLines={2}
                      isTruncated
                      width="100%"
                      style={{ fontWeight: 'bold' }}
                      px={2}
                      py={1}
                    >
                      {address ? address : title}
                    </Text>
                  </Flex>


                  {(status == "Rejected" || status == "Changes Required" || status == "Sold" || status == "Archived") ? (
                    <Flex
                      mb="10px"
                      mr="3px"
                      position="absolute"
                      bottom="0"
                      right="0"
                    >

                      <Icon
                        w="14px"
                        h="14px"
                        me="5px"
                        color={
                          status === "Rejected"
                            ? "red.500"
                            : status === "Changes Required"
                              ? "orange.500"
                              : (status === "Sold" || status == "Archived")
                                ? "#66BB6A"
                                : null
                        }
                        as={
                          status === "Rejected"
                            ? MdCancel
                            : status === "Changes Required"
                              ? MdOutlineError
                              : (status === "Sold" || status == "Archived")
                                ? MdAttachMoney
                                : null
                        }
                      />
                    </Flex>) : null}


                </Flex>
              )
            }}


          />

        </Flex>

      </Flex>

    </Flex>
  );
}

export default Kanban;
