import {
  Avatar, MenuButton, Box, SimpleGrid, Grid, Flex, Button, Input, Icon,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { MdCheckCircle, MdCancel, MdOutlineError, MdVisibility, MdOutlineAdd, MdAdd } from "react-icons/md";
import { FiSend } from "react-icons/fi";
import React, { useState, useEffect } from 'react';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput
} from "@chatscope/chat-ui-kit-react";
import { setIn } from "formik";


function ChatMessaging() {
  const [messages, setMessages] = useState([{
    message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    sentTime: "15 mins ago",
    sender: "Emily",
    direction: "incoming",
    position: "last",
  }]);
  const [inputValue, setInputValue] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  const types = ["image/png", "image/jpeg"];

  const handleFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = types.join(", ");
    input.onchange = handleChange;
    input.click();
  };

  useEffect(() => {

  }, [messages]);

  const handleMessageSend = () => {
    if (inputValue !== "") {
      setMessages([...messages, {
        message: inputValue,
        sentTime: "just now",
        sender: "Joe",
      }]);
      setInputValue("");
      setError("");
      setPreviewUrl("");
      setFile(null);
    }
  };
  const handleChange = (e) => {
    let selected = e.target.files[0];

    if (selected && types.includes(selected.type)) {
      setFile(selected);
      setInputValue(selected.name);
      setPreviewUrl(URL.createObjectURL(selected));
      setError("");
    } else {
      setFile(null);
      setError("Please select an image file (png or jpeg)");
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const [previewUrl, setPreviewUrl] = useState(null);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ md: 1 }}
        spacing={{ base: "20px", xl: "1px" }}>
        <Card direction="column" px="15px" overflowX={{ sm: "scroll", lg: "hidden" }}>
          <Box style={{ position: "relative", height: "650px" }}>

            <ChatContainer>
              <MessageList>
                {messages.map(message =>
                  <Flex justifyContent="left" alignItems="left">

                    <Avatar
                      _hover={{ cursor: "pointer" }}
                      color='white'
                      name='College Souk'
                      bg='#11047A'
                      size='sm'
                      w='40px'
                      h='40px'
                      m='4px'
                      status="available"
                    />
                    <Message
                      model={{
                        message: message.message,
                        sentTime: message.sentTime,
                        sender: message.sender,
                        direction: message.direction,
                      }} />
                  </Flex>
                )}
              </MessageList>
            </ChatContainer>
          </Box>

          <Flex justifyContent="center" alignItems="center">
            <Icon
              m={2}
              w='40px'
              h='40px'
              me='15px'
              color={
                "black"
              }
              onClick={handleFile}

              as={
                MdOutlineAdd
              }
            />
            <Input m={2} placeholder="Type something ..."
              onChange={handleInputChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleMessageSend();
                }
              }}
              value={inputValue}
              backgroundColor="gray.50" />
            {previewUrl && (
              <Box as="img" src={previewUrl} maxW="100px" maxH="100px" />
            )}
            <Icon
              m={2}
              w="35px"
              h="35px"
              me="5px"
              color="black"
              as={FiSend}
              onClick={handleMessageSend}
            />
          </Flex>
        </Card>
      </SimpleGrid>
    </Box>
  );
}

export default ChatMessaging;