import { apiSlice } from "../../api/apiSlice";
import { usersApiSlice } from "../../slices/users/usersSlice";
import { subApiSlice } from '../../slices/subscription/subscription';
import { setJwtCookieWithTimeout, removeJwtCookie } from '../../../util/util';
import { listingsApiSlice } from "../listings/listingsslice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: '/auth/login',
                method: 'POST',
                body: credentials
            }),
            invalidatesTags: ["auth", "user", "sub", "listing"],
            transformResponse: async (data) => {
                const { token, id, email, school } = data;
                await setJwtCookieWithTimeout(token)
                    .then(() => {
                        console.log('Cookie was set successfully');
                        usersApiSlice.endpoints.getUser.initiate();
                        usersApiSlice.endpoints.getUserStats.initiate(school);
                        usersApiSlice.endpoints.getLeads.initiate(email);
                        usersApiSlice.endpoints.getTasks.initiate(email);
                        listingsApiSlice.endpoints.getListings.initiate(email);
                        listingsApiSlice.endpoints.getListingsStatus.initiate(email);
                        subApiSlice.endpoints.getSubDetails.initiate(email);
                    })
                    .catch((error) => {
                        console.error('Failed to set cookie:', error);
                    });
            }
        }),
        register: builder.mutation({
            query: registrationDetails => ({
                url: '/auth/register',
                method: 'POST',
                body: registrationDetails,
            }),
            invalidatesTags: ["auth", "user", "sub", "listing"],
            transformResponse: async (data) => {
                const { token, id, email } = data;
                await setJwtCookieWithTimeout(token)
                    .then(() => {
                        console.log('Cookie was set successfully');
                        usersApiSlice.endpoints.getUser.initiate();
                        usersApiSlice.endpoints.getUserStats.initiate(email);
                        usersApiSlice.endpoints.getLeads.initiate(email);
                        usersApiSlice.endpoints.getTasks.initiate(email);
                        listingsApiSlice.endpoints.getListings.initiate(email);
                        listingsApiSlice.endpoints.getListingsStatus.initiate(email);

                        subApiSlice.endpoints.getSubDetails.initiate(email);
                        // dispatch(setCredentials({ ...userData, user }));

                    })
                    .catch((error) => {
                        console.error('Failed to set cookie:', error);
                    });

            }
        }),
        googleLogin: builder.mutation({
            query: token => ({
                url: `/auth/login/google/${token}`,
                method: 'POST',
            }),
            invalidatesTags: ["auth", "user", "sub", "listing"],
            transformResponse: async (data) => {
                const { token, id, email } = data;
                await setJwtCookieWithTimeout(token)
                    .then(() => {
                        console.log('Cookie was set successfully');
                        usersApiSlice.endpoints.getUser.initiate();
                        usersApiSlice.endpoints.getUserStats.initiate(email);
                        usersApiSlice.endpoints.getLeads.initiate(email);
                        usersApiSlice.endpoints.getTasks.initiate(email);
                        listingsApiSlice.endpoints.getListings.initiate(email);
                        listingsApiSlice.endpoints.getListingsStatus.initiate(email);
                        subApiSlice.endpoints.getSubDetails.initiate(email);
                    })
                    .catch((error) => {
                        console.error('Failed to set cookie:', error);
                    });
            }
        }),
        googleRegister: builder.mutation({
            query: token => ({
                url: `/auth/login/google/${token}`,
                method: 'POST',
            }),
            invalidatesTags: ["auth", "user", "sub", "listing"],
            transformResponse: async (data) => {
                const { token, id, email } = data;
                await setJwtCookieWithTimeout(token)
                    .then(() => {
                        console.log('Cookie was set successfully');
                        usersApiSlice.endpoints.getUser.initiate();
                        usersApiSlice.endpoints.getUserStats.initiate(email);
                        usersApiSlice.endpoints.getLeads.initiate(email);
                        usersApiSlice.endpoints.getTasks.initiate(email);
                        listingsApiSlice.endpoints.getListings.initiate(email);
                        listingsApiSlice.endpoints.getListingsStatus.initiate(email);

                        subApiSlice.endpoints.getSubDetails.initiate(email);
                        // dispatch(setCredentials({ ...userData, user }));

                    })
                    .catch((error) => {
                        console.error('Failed to set cookie:', error);
                    });

            }
        }),
        logout: builder.mutation({
            query: () => ({
                url: '/auth/logout',
                method: 'POST'
            }),
            invalidatesTags: ["auth", "user", "sub", "listing"],
            transformResponse: async (data) => {
                removeJwtCookie();
            },
            transformErrorResponse: async (data) => {
                removeJwtCookie();
            }
        }),
        changePassword: builder.mutation({
            query: (changes) => ({
                url: '/auth/change/password',
                body: changes,
                method: 'POST'
            }),
        }),
        sendResetPassword: builder.mutation({
            query: (email) => ({
                url: '/auth/reset/password',
                params: { email: email },
                method: 'POST'
            }),
        }),
        resetPassword: builder.mutation({
            query: (changes) => ({
                url: '/auth/reset/password/token',
                body: changes,
                method: 'POST'
            }),
        })
    })
})

export const {
    useResetPasswordMutation,
    useSendResetPasswordMutation,
    useChangePasswordMutation,
    useLoginMutation,
    useGoogleLoginMutation,
    useGoogleRegisterMutation,
    useRegisterMutation,
    useLogoutMutation
} = authApiSlice;
