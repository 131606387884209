import {
    Button, Box, ButtonGroup,
    Flex, Text, Divider, Center,
    FormLabel, InputGroup, Icon,
    Input, InputLeftElement,
    Spinner

} from '@chakra-ui/react'

import EditMarketplaceForm from "views/admin/view/EditMarketplaceForm";
import EditApparelForm from "views/admin/view/EditApparelForm";
import EditServiceForm from "views/admin/view/EditServiceForm";

import EditHousingForm from "views/admin/view/EditHousingForm";
import Dropzone from "views/admin/listing/components/Dropzone";
import { MdUpload, MdAddCircle, MdCircle, MdAttachMoney } from "react-icons/md";
import { NotAllowedIcon, TimeIcon } from '@chakra-ui/icons';
import {
    BsFillCheckCircleFill
} from "react-icons/bs";
import { FaPipe } from 'react-icons/fa';
import React, { useState, useEffect, useRef } from "react";
import { getStageMessage, getStageBarColor, isPlanEligibleToPostToAllSchools, isPremium, separateFilesAndUrls } from "../../../util/util"
import {
    useEditApparelListingMutation,
    useEditHousingListingMutation,
    useEditMarketplaceListingMutation,
    useEditServiceListingMutation,
    useGetSubcategoriesMarketplaceQuery,
    useGetApparelCategoriesQuery,
    useGetServicesCategoriesQuery
} from "../../../data/slices/listings/listingsslice";
import { useGetUserQuery } from "../../../data/slices/users/usersSlice";
import { useFormikContext, FormikProvider } from 'formik';
import { useGetSubDetailsQuery } from 'data/slices/subscription/subscription';

export default function FormsWrapper(props) {
    const category = props.data?.category;
    const status = props.data?.status;
    const existingImages = props.data?.images;
    const stage = getStageMessage(status);
    const stageBarColor = getStageBarColor(status);
    const statusIcon = getStatusIcon(status);
    const [notes, setNotes] = useState(props.data?.notes);
    const { data, isLoading: isLoadingUser, isSuccess: isSuccessUser, isError: isErrorUser, error: userError } = useGetUserQuery();
    const [formValues, setFormValues] = useState({});

    const isEditingDisabled = (status == "Approved" || status == "Sold" || status == "Archived");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const { data: subcategories, isLoading: isLoadingSubcategories } = useGetSubcategoriesMarketplaceQuery();
    const { data: apparelTypes, isLoading: isLoadingApparelTypes, isSuccess: isSuccessApparelTypes, isError: isErrorApparelTypes, error: errorApparelTypes } = useGetApparelCategoriesQuery();
    const { data: serviceCategories, isLoading: isLoadingServices, isSuccess: isSuccessService, isError: isErrorService, error: errorService } = useGetServicesCategoriesQuery();
    const [updateHousingListing, { isLoading: isLoadingHousingListing }] = useEditHousingListingMutation();
    const [updateApparelListing, { isLoading: isLoadingApparelListing }] = useEditApparelListingMutation();
    const [updateServiceListing, { isLoading: isLoadingServiceListing }] = useEditServiceListingMutation();
    const [updateMarketplaceListing, { isLoading: isLoadingMarketplaceListing }] = useEditMarketplaceListingMutation();
    const { data: subDetails, error, isLoading } = useGetSubDetailsQuery(data?.email);
    const isPlanEligibleToPostToAll = isPlanEligibleToPostToAllSchools(subDetails);

    const ref = useRef(null);

    function handleClick() {
        ref.current.submit();
        // This won't work because the DOM node isn't exposed:
        // ref.current.style.opacity = 0.5;
    }
    const handleSelectedFiles = (files) => {
        setSelectedFiles(files);
    };

    const handleDelete = async () => {
        if (props.data && props.data._id) {
            props.handleDeleteListing(props.data._id);
        }
    };

    const submitListingToApi = async (formDetails, resetSelectedSchools) => {
        const { files, urls } = await separateFilesAndUrls(selectedFiles);

        if (true) {
            try {
                var formWithUpdates = {
                    ...formDetails,
                    notes: notes,
                    ownerEmail: data?.email,
                    nonDeletedImages: urls
                }

                const formData = new FormData();
                formData.append('updatedListingDetails',
                    new Blob([JSON.stringify(formWithUpdates)], {
                        type: 'application/json'
                    }));
                if (files && files.length > 0) {
                    for (let i = 0; i < files.length; i++) {
                        formData.append('images', files[i]);
                    }
                } else {
                    formData.append('images', []);
                }


                switch (formWithUpdates?.type) {
                    case undefined:
                        break;
                    case "Marketplace":
                        const responseMarketplace = await updateMarketplaceListing(formData).unwrap();
                        props.closeModal();
                        break;
                    case "Housing Arrangements":
                        const responseHousing = await updateHousingListing(formData).unwrap();
                        props.closeModal();
                        break;
                    case "Service":
                        const responseService = await updateServiceListing(formData).unwrap();
                        props.closeModal();
                        break;
                    case "Apparel":
                        const responseApparel = await updateApparelListing(formData).unwrap();
                        props.closeModal();
                        break;
                    default:
                        break;
                }
            } catch (err) {
                console.log(err);
                console.log("error updating listing");
            }
        } else {
            //onOpenPricing();
        }
    }



    function getStatusIcon(status) {
        if (!status) {
            return null;
        }

        switch (status.toUpperCase()) {
            case 'NEW':
                return <MdAddCircle color='green' mt='9px' />;
            case 'APPROVED':
                return <BsFillCheckCircleFill color='green' mt='9px' />;
            case 'REJECTED':
                return <NotAllowedIcon color='red' mt='5px' />;
            case 'PENDING':
                return <TimeIcon color='blue' mt='5px' />;
            case 'SOLD':
                return <MdAttachMoney color='green' mt='5px' />;
            default:
                return null; // or return a default icon if needed
        }
    }

    if (isLoadingSubcategories || isLoadingApparelTypes || isLoadingServices) {
        return (<Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            height='100vh'
        >
            <Spinner size='xl' />
        </Box>)
    }
    return (
        <Flex flexDirection='row' overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Flex color='black' width='50%'>
                {category === "Housing Arrangements" && <EditHousingForm data={props.data} handleSubmit={submitListingToApi} isEditingDisabled={isEditingDisabled} isPlanEligibleToPostToAllSchools={isPlanEligibleToPostToAll} ref={ref} />}
                {category === "Marketplace" && <EditMarketplaceForm data={props.data} handleSubmit={submitListingToApi} subcategories={subcategories} isEditingDisabled={isEditingDisabled} isPlanEligibleToPostToAllSchools={isPlanEligibleToPostToAll} ref={ref} />}
                {category === "Apparel" && <EditApparelForm data={props.data} handleSubmit={submitListingToApi} apparelTypes={apparelTypes} isEditingDisabled={isEditingDisabled} isPlanEligibleToPostToAllSchools={isPlanEligibleToPostToAll} ref={ref} />}
                {category === "Service" && <EditServiceForm data={props.data} handleSubmit={submitListingToApi} serviceCategories={serviceCategories} isEditingDisabled={isEditingDisabled} isPlanEligibleToPostToAllSchools={isPlanEligibleToPostToAll} ref={ref} />}
            </Flex>
            <Center height='auto'>
                <Divider orientation='vertical' borderColor='black' />
            </Center>
            <Flex flexDirection='column' mt='21px' justify='space-between'>
                {/* <FormLabel
                    ms='37px'
                    fontSize='sm'
                    fontWeight='100'
                    color='gray'
                    isRequired={true}
                    display='flex'>
                    <Text color='black' fontWeight='bold' fontSize='xl'>Name</Text>
                </FormLabel>
                <InputGroup size='md' width='500px' ms='37px'>
                    <Input
                        isRequired={false}
                        fontSize='sm'
                        placeholder='1 bed 1 bath'
                        mb='24px'
                        size='lg'
                        type='tel'
                        variant='auth'
                        w='90%'
                    />
                </InputGroup> */}
                <Flex >
                    <FormLabel
                        ms='37px'
                        fontSize='sm'
                        fontWeight='100'
                        color='gray'
                        isRequired={true}
                        display='flex' width='100px'>
                        <Text color='black' fontWeight='bold' fontSize='xl' >Status</Text>
                    </FormLabel>
                </Flex>
                <InputGroup size='md' width='500px' ms='37px'>
                    <InputLeftElement mt='3px'
                        pointerEvents='none'
                        children={statusIcon}
                    />
                    <Input
                        isRequired={false}
                        fontSize='sm'
                        value={status}
                        isDisabled={true}
                        mb='24px'
                        size='lg'
                        variant='auth'
                        w='90%'
                        color='black'
                    />
                </InputGroup>
                <FormLabel
                    ms='37px'
                    fontSize='sm'
                    fontWeight='100'
                    color='gray'
                    isRequired={true}
                    display='flex'>
                    <Text color='black' fontWeight='bold' fontSize='xl'>Stage</Text>
                </FormLabel>
                <InputGroup size='md' width='500px' ms='37px'>
                    <InputLeftElement mt='3px'
                        pointerEvents='none'
                        children={<MdCircle w={6} h={6} style={{ color: stageBarColor }} />}
                    />
                    <Input
                        isRequired={false}
                        fontSize='sm'
                        mb='24px'
                        size='lg'
                        value={stage}
                        isDisabled={true}
                        variant='auth'
                        w='90%'
                    />
                </InputGroup>
                <FormLabel
                    ms='37px'
                    fontSize='sm'
                    fontWeight='100'
                    color='gray'
                    isRequired={true}
                    display='flex'>
                    <Text color='black' fontWeight='bold' fontSize='xl'>Notes</Text>
                </FormLabel>
                <InputGroup size='md' width='500px' ms='37px'>
                    <Input
                        isRequired={false}
                        fontSize='sm'
                        defaultValue={notes}
                        placeholder='Enter some notes regarding this listing, these notes are only visible to you.'
                        mb='24px'
                        size='lg'
                        type='tel'
                        onChange={(evt) => {
                            console.log("txt", evt.target.value)
                            setNotes(evt.target.value);
                        }}
                        variant='auth'
                        w='90%'
                        h='100px'
                    />
                </InputGroup>
                <Flex flexDirection='column' mb={8} >
                    <Dropzone ms='37px' width='450px'
                        me='36px'
                        h='175px'
                        onSelectedFiles={handleSelectedFiles}
                        maxH={{ base: "80%", lg: "80%", "2xl": "100%" }}
                        minH={{ base: "80%", lg: "80%", "2xl": "100%" }}
                        existingImages={existingImages}
                        content={
                            <Box>
                                <Icon as={MdUpload} w='80px' h='80px' color='gray' />
                                <Flex justify='center' mx='auto' mb='12px'>
                                    <Text fontSize='xl' fontWeight='700' color='gray'>
                                        Upload Files
                                    </Text>
                                </Flex>
                                <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                                    PNG and JPG files are allowed
                                </Text>
                            </Box>
                        }
                    />
                    <Flex flexDirection='row' width='400px' mt='185px' ms='60px'>
                        <Button width='370px' color='black' bg='white' boxShadow='0px 4px 4px rgba(0, 0, 0, 0.15)' type="submit" borderRadius='50px'
                            isLoading={props.isDeleting} onClick={handleDelete}
                            fontWeight='medium' variant='outline'>
                            Delete
                        </Button>
                        <Button ms='20px' width='370px' color='black' bg='#A3F579' boxShadow='0px 4px 4px rgba(0, 0, 0, 0.15)' type="button" borderRadius='50px' fontWeight='medium' onClick={handleClick}
                            isLoading={isLoadingHousingListing || isLoadingApparelListing || isLoadingServiceListing || isLoadingMarketplaceListing}>
                            Save Changes
                        </Button>
                    </Flex>
                </Flex>

            </Flex>
        </Flex>

    )
}